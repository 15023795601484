import React, {useCallback, useEffect, useMemo} from "react";
import classnames from "classnames";
import {useFormikContext} from "formik";

import {Service} from "../../../../data";
import {DropdownButton} from "../../../../components/input";
import {Span2, Span5} from "../../../../components/text";
import {Icon} from "../../../../components/images";
import {FormValues} from "./types";

import styles from "./styles.module.scss";
const bStyles = classnames.bind(styles);

type ShareToValue = {id: string; networks: Service[]; peakTime?: boolean} | null;
interface RecipientProps {
	options: {label: string; id: string; networks: Service[]; peakTime?: boolean}[];
	onChange: (field: string, value: ShareToValue) => void;
	className?: string;
}

export const Recipient = ({onChange, options, className}: RecipientProps) => {
	const {values, initialValues, setFieldValue, errors} = useFormikContext<FormValues>();
	const {recipient} = values;
	const {recipient: recipientErrors} = errors;

	const dropdownOptions = useMemo(
		() =>
			options.map(o => ({
				label: o.label,
				onClick: () => onChange("recipient", {id: o.id, networks: o.networks, peakTime: o.peakTime}),
			})),
		[options, onChange]
	);
	const dropdownValue = useMemo(() => options.find(o => o.id === recipient?.id)?.label, [options, recipient]);
	const onNetworkChange = useCallback(
		(network: Service) => {
			onChange("recipient", {
				id: recipient.id,
				networks: recipient.networks.includes(network)
					? recipient.networks.filter(n => n !== network)
					: [...recipient.networks, network],
				peakTime: recipient.peakTime,
			});
		},
		[recipient, onChange]
	);
	const networks = options.find(o => o.id === recipient?.id)?.networks || [];
	useEffect(() => {
		if (options.length === 0 || !recipient?.id) return;
		const option = options.find(o => o.id === recipient?.id);
		const hasNetwork = recipient?.networks?.some(n => !option?.networks?.includes(n));
		if (!option) {
			setFieldValue("recipient", null);
			return;
		}
		const hasIlegalNetwork = recipient?.networks?.some(n => !option?.networks?.includes(n));
		if (hasNetwork && option?.networks?.length && hasIlegalNetwork) {
			onChange("recipient", {...recipient, networks: option?.networks});
		}
	}, [recipient, options, onChange, initialValues, setFieldValue]);

	return (
		<div className={bStyles(styles.recipientContainer, className)}>
			<div className={bStyles(styles.recipient)}>
				<div className={bStyles(styles.dropdown, {[styles.noValue]: !dropdownValue})}>
					<Span2 bold>Share to:</Span2>
					<DropdownButton
						className={styles.dropdownButton}
						value={dropdownValue ?? "Select accounts"}
						color={"black"}
						invert
						border={false}
						options={dropdownOptions}
						arrow
					/>
				</div>
				{!!networks.length && (
					<div className={styles.networks}>
						{networks.map(s => (
							<div
								key={s}
								className={bStyles(styles.network, {[styles.selected]: recipient?.networks?.includes(s)})}
								onClick={() => onNetworkChange(s)}
							>
								<Icon icon={`filled-${s}`} width={16} height={16} viewBox={"0 0 16 16"} />
							</div>
						))}
					</div>
				)}
			</div>
			{!!recipientErrors?.networks && (
				<div>
					<Span5 color={"red"}>{recipientErrors.networks}</Span5>
				</div>
			)}
		</div>
	);
};
