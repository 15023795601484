import {useCallback, useEffect, useMemo, useRef} from "react";
import {Dayjs} from "dayjs";
import {useFormikContext} from "formik";

import {FormValues} from "./types";
import {
	OPENGRAPH_FROM_URL,
	useMyUser,
	clearTypename,
	ShareEvent,
	services,
	UPLOAD_IMAGE,
} from "../../../../data";
import {useCanvaDesign} from "../../../../hooks/use-canva-design";
import {useMutationToast} from "../../../../toast";
import {getURL, isURL} from "../../../../utils/text";
import {Editor, networkCaptionMaxLength} from "../../components";
import {BottomToolbar, TopToolbar} from "./toolbars";
import {EditorHeader} from "./editor-header";
import {Setter} from "../../../../types";

import styles from "./styles.module.scss";

interface PostEditorProps {
	shareEvent?: ShareEvent;
	disabled?: boolean;
	shareFromUrl?: string;
	totalShareEvents?: number;
	setShareFromUrl?: Setter<string | undefined>;
}

const getActiveNetwork = (network, validNetworks) => {
	if (validNetworks.length === 0 || network === "general") return "general";
	if (validNetworks.includes(network)) return network;
	return validNetworks[0];
};

const getOpengraphType = opengraph => (!!opengraph?.image || !!opengraph?.video ? "media" : "text");

export const PostEditor = ({
	shareEvent,
	shareFromUrl,
	setShareFromUrl,
	totalShareEvents,
	disabled,
}: PostEditorProps) => {
	const me = useMyUser();
	const [opengraphFromURL, {loading: loadingURL}] = useMutationToast(OPENGRAPH_FROM_URL);
	const [uploadImage, {loading: uploading}] = useMutationToast(UPLOAD_IMAGE);
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const {values, errors, setFieldValue} = useFormikContext<FormValues>();
	const {id, opengraphs, recipient, schedule, activeNetwork: dynamicActiveNetwork, perNetwork, url} = values;
	const activeNetwork = getActiveNetwork(
		shareEvent?.network ?? dynamicActiveNetwork,
		recipient?.networks ?? []
	);
	const editorData = opengraphs?.[activeNetwork] ?? opengraphs?.general;
	const title = editorData?.title;
	const comment = editorData?.comment ?? "";
	const description = editorData?.description;
	const image = editorData?.image;
	const images = editorData?.images;
	const video = editorData?.video;
	const {opengraphs: opengraphsErrors, schedule: scheduleErrors} = errors;
	const editorErrors = opengraphsErrors?.[activeNetwork] ?? opengraphsErrors?.general;
	const scheduleNetworkErrors = scheduleErrors?.[activeNetwork];
	const editorErrorsMsg = useMemo(() => {
		const editorMsgs = typeof editorErrors === "object" ? Object.values(editorErrors) : [];
		const scheduleMsgs =
			typeof scheduleNetworkErrors === "object" ? Object.values(scheduleNetworkErrors) : [];
		return [...editorMsgs, ...scheduleMsgs]
			.filter(v => !!v)
			.map(error => (typeof error === "string" ? error : error?.[0] ?? ""));
	}, [editorErrors, scheduleNetworkErrors]);
	const postType = useMemo(() => {
		if (url) return "url";

		const selectedNetworks: string[] = recipient?.networks ?? [];
		const activeOpengraphs = Object.keys(opengraphs).filter(key =>
			perNetwork ? key !== "general" && selectedNetworks.includes(key) && opengraphs[key] : key === "general"
		);
		if (activeOpengraphs.length === 0) return getOpengraphType(opengraphs.general);
		const types = activeOpengraphs.map(key => getOpengraphType(opengraphs[key] ?? opengraphs.general));
		if (types.find(type => type === "media")) return "media";

		return "text";
	}, [url, opengraphs, perNetwork, recipient]);

	const onEditorChange = useCallback(
		async (field, value) => {
			let focus = false;
			const pos = textareaRef?.current?.selectionStart ?? 0;
			const focusInput = () => {
				textareaRef?.current?.focus();
				textareaRef?.current?.setSelectionRange(pos + value.length, pos + value.length);
			};

			if (field === "comment" && isURL(value) && postType === "text") {
				const newUrl = getURL(value);
				const {data} = await opengraphFromURL({variables: {url: newUrl}});
				const opengraph = clearTypename(data?.opengraphFromURL);

				if (Object.keys(opengraph).length) {
					setFieldValue(`opengraphs`, {
						general: {
							...opengraph,
							comment: "",
						},
						...services.reduce((acc, service) => {
							acc[service] = null;
							return acc;
						}, {}),
					});
					setFieldValue("url", newUrl);
					setShareFromUrl?.(newUrl ?? "");
					return;
				}
			}

			if (field === "url") {
				setFieldValue("url", value);
				[...values.recipient.networks, "general"].forEach(network => {
					opengraphs?.[network] &&
						setFieldValue(`opengraphs.${network}`, {
							comment: comment === description ? "" : comment,
						});
				});
				setShareFromUrl?.(value);
				return;
			}

			if (field === "emoji") {
				field = "comment";
				value = `${comment.slice(0, pos)}${value}${comment.slice(pos)}`;
				focus = true;
			}

			if (!opengraphs[activeNetwork]) {
				setFieldValue(`opengraphs.${activeNetwork}`, {...opengraphs.general});
			}
			setFieldValue(`opengraphs.${activeNetwork}.${field}`, value);

			if (focus) {
				setTimeout(focusInput, 0);
			}
		},
		[
			activeNetwork,
			opengraphs,
			comment,
			setFieldValue,
			opengraphFromURL,
			textareaRef,
			description,
			values,
			setShareFromUrl,
			postType,
		]
	);

	useEffect(() => {
		if (shareFromUrl && url !== shareFromUrl && isURL(shareFromUrl)) onEditorChange("comment", shareFromUrl);
	}, [shareFromUrl, url, onEditorChange]);

	const onScheduleChange = useCallback(
		(value?: Dayjs | null) => {
			const newSchedule = {
				...schedule,
				...(perNetwork
					? {
							[activeNetwork]: {
								...schedule?.[activeNetwork],
								scheduledFor: value,
								peakTime: !value && me.peakTime,
								timeslot: !value && !me.peakTime,
							},
					  }
					: recipient?.networks?.reduce(
							(acc, n) => ({
								...acc,
								[n]: {
									...schedule?.[n],
									scheduledFor: value,
									peakTime: !value && me.peakTime,
									timeslot: !value && !me.peakTime,
								},
								general: {
									...schedule?.[n],
									scheduledFor: value,
									peakTime: !value && me.peakTime,
									timeslot: !value && !me.peakTime,
								},
							}),
							{}
					  )),
			};
			setFieldValue("schedule", newSchedule);
		},
		[setFieldValue, recipient, schedule, activeNetwork, perNetwork, me.peakTime]
	);
	const onClear = useCallback(
		field => {
			if (field === "comment") {
				onEditorChange(field, "");
				return;
			}

			if (field === "url") {
				setShareFromUrl?.(undefined);
				setFieldValue("url", null);
				setFieldValue("opengraphs", {
					general: {
						comment: opengraphs.general.comment,
						description: "",
						image: null,
						title: "",
						video: null,
					},
				});
				return;
			}
		},
		[setFieldValue, onEditorChange, opengraphs, setShareFromUrl]
	);

	const onCanvaFileSelect = useCallback(
		(url: string) => {
			onEditorChange("image", url);
		},
		[onEditorChange]
	);

	const {createDesignJob, loading: loadingCanvaMedia} = useCanvaDesign({
		onCompleted: onCanvaFileSelect,
	});

	const multiImage = postType === "media" && !video;

	return (
		<Editor
			className={styles.editor}
			title={title}
			comment={comment}
			video={video}
			image={image}
			images={images}
			multiImage={multiImage}
			description={description}
			url={url}
			activeNetwork={activeNetwork}
			onChange={onEditorChange}
			onClear={onClear}
			textareaRef={textareaRef}
			maxLength={networkCaptionMaxLength[activeNetwork]}
			loading={loadingURL}
			loadingMedia={uploading || loadingCanvaMedia}
			Header={<EditorHeader activeNetwork={activeNetwork} shareEvent={shareEvent} />}
			TopToolbar={
				!id && perNetwork ? (
					<TopToolbar
						activeNetwork={activeNetwork}
						availableNetworks={recipient?.networks}
						onChange={setFieldValue}
					/>
				) : undefined
			}
			BottomToolbar={
				<BottomToolbar
					comment={comment || description || title}
					image={image}
					images={images}
					multiImage={multiImage}
					uploadImage={uploadImage}
					redirectOnDelete={totalShareEvents === 1}
					createDesignJob={createDesignJob}
					onChange={onEditorChange}
					onScheduleChange={onScheduleChange}
					scheduledFor={schedule?.[activeNetwork]?.scheduledFor}
					peakTime={me.peakTime}
					disabled={disabled}
					shareEvent={shareEvent}
				/>
			}
			aiEnabled={me.org.options.ai}
			showDescription={["general", "facebook"].includes(activeNetwork)}
			errors={editorErrorsMsg}
		/>
	);
};
