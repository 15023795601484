import {useCallback, useMemo, useState} from "react";
import classnames from "classnames";
import {useLocalStorage} from "react-use";
import {Link} from "react-router-dom";

import {UPDATE_USER, useMyUser} from "../data";
import {navigationRoutes} from "./router";
import {Icon, Logo, MenuIcon, NavIcon} from "../components/images";
import {UserAvatar} from "../components/user-avatar";
import {Span3} from "../components/text";
import config from "../config";
import {useMutationToast} from "../toast";
import {useConfirmModal} from "../modals";

import styles from "./layout.module.scss";

interface SidebarProps {
	open: () => void;
}

interface AccountProps {
	desktopExpanded: boolean;
	switchRedirectModalOpen: () => void;
}

const Account = ({desktopExpanded, switchRedirectModalOpen}: AccountProps) => {
	const [submenuOpen, setSubmenuOpen] = useState(false);
	const user = useMyUser();
	const isInternal = useMemo(() => user?.internal, [user?.internal]);

	const [updateUser, {loading: saving}] = useMutationToast(UPDATE_USER);

	const toggleRole = useCallback(() => {
		updateUser({
			variables: {
				id: user.id,
				changes: {
					role: user.role === "admin" ? "USER" : "ADMIN",
				},
			},
		}).then(() => {
			window.location.reload();
		});
	}, [updateUser, user.id, user.role]);
	const confirmDeleteModal = useConfirmModal(
		() => ({
			title: `Are you sure?`,
			body: `Are you sure you want to change your role to ${
				user.role === "admin" ? "user" : "admin"
			}? The page will reload after the change is made.`,
			confirmColor: "pink",
			onConfirm: toggleRole,
			confirming: saving,
		}),
		[saving, toggleRole, user.role]
	);

	const openModal = useCallback(() => {
		confirmDeleteModal.open();
	}, [confirmDeleteModal]);

	const handleLogout = () => window.open(`${config.appUrl}/logout`, "_self");

	return (
		<div
			className={styles.account}
			onMouseEnter={() => setSubmenuOpen(true)}
			onMouseLeave={() => setSubmenuOpen(false)}
		>
			<div className={styles.avatar}>
				<div className={styles.avatarContainer}>
					<UserAvatar size="extraSmall" />
				</div>

				<Span3 color="grey" className={classnames(!desktopExpanded && styles.avatarText)}>
					Account
				</Span3>
			</div>

			{submenuOpen && (
				<div className={styles.submenu} tabIndex={0}>
					<Link
						to={"/settings/personal"}
						className={styles.submenuItem}
						onClick={() => setSubmenuOpen(false)}
						color="black"
					>
						<Icon color="grey" icon={"settings"} />
						<Span3 className={styles.menuLinkText}>{"Personal Account"}</Span3>
					</Link>

					{user.org.options.allowRedirect && user.role === "admin" && (
						<div
							className={classnames(styles.submenuItem, styles.smallScreen)}
							onClick={switchRedirectModalOpen}
						>
							<Icon color="grey" icon={"switch-to-classic"} />
							<Span3 className={styles.menuLinkText}>Switch to Classic</Span3>
						</div>
					)}
					{isInternal && (
						<>
							<div className={styles.submenuItem} onClick={openModal}>
								<Icon color="grey" icon={"groupManagement"} />
								<Span3 className={styles.menuLinkText}>
									Toggle role to {user.role === "admin" ? "user" : "admin"}
								</Span3>
							</div>

							<Link
								to={config.internalToolsUrl}
								target="_blank"
								className={classnames(styles.submenuItem, styles.smallScreen)}
								color="black"
							>
								<Icon color="grey" icon={"key"} />
								<Span3 className={styles.menuLinkText}>{"Internal Tools"}</Span3>
							</Link>
						</>
					)}

					<Link
						to={"https://cvssupport.wpenginepowered.com"}
						className={classnames(styles.submenuItem, styles.smallScreen)}
						onClick={() => setSubmenuOpen(false)}
						color="black"
						target="_blank"
					>
						<Icon color="grey" icon={"help"} />
						<Span3 className={styles.menuLinkText}>{"Help"}</Span3>
					</Link>
					<div className={styles.submenuItem} onClick={handleLogout}>
						<Icon color="grey" icon={"logout"} />
						<Span3 className={styles.menuLinkText}>Logout</Span3>
					</div>
				</div>
			)}
		</div>
	);
};

export const Sidebar = ({open}: SidebarProps) => {
	const [cookieValue, setDesktopExpanded] = useLocalStorage("nav-L", true);
	const desktopExpanded = useMemo(() => cookieValue ?? true, [cookieValue]);
	const [mobileExpanded, setMobileExpanded] = useState(false);

	const user = useMyUser();

	const allowedNavigations = useMemo(
		() => navigationRoutes.filter(item => !item.internalOnly || user?.internal),
		[user?.internal]
	);
	const toggleMobileExpand = useCallback(() => setMobileExpanded(c => !c), [setMobileExpanded]);
	const closeMobileExpand = useCallback(() => setMobileExpanded(false), [setMobileExpanded]);

	return (
		<div className={classnames(styles.nav, desktopExpanded && styles.fullWidth)}>
			<Icon
				icon={"menu-collapse"}
				height={16}
				width={16}
				onClick={() => setDesktopExpanded(!desktopExpanded)}
				className={classnames(styles.arrowCollapseDesktop, !desktopExpanded && styles.rotate)}
			/>
			<Link to={"/feed/hub"} className={styles.menuLink}>
				{user.org.settings.uiLogo ? (
					<div className={styles.logo}>
						<img src={user.org.settings.uiLogo} />
					</div>
				) : (
					<Logo className={styles.logo} />
				)}
			</Link>
			<div className={classnames(styles.items, mobileExpanded && styles.open)}>
				<div className={styles.itemsContainer}>
					<div className={styles.rounded}>
						{allowedNavigations
							.filter(nav => !nav.bottom)
							.map(({path, ...props}) => (
								<NavIcon
									key={path}
									{...props}
									path={path}
									onClick={closeMobileExpand}
									expanded={desktopExpanded}
								/>
							))}

						<div className={styles.bottomContainer}>
							{user.org.options.allowRedirect && user.role === "admin" && (
								<NavIcon
									name="Switch to Classic version"
									icon="switch-to-classic"
									path=""
									bottom={true}
									modal={open}
									className={styles.bottom}
									expanded={desktopExpanded}
								/>
							)}
							{allowedNavigations
								.filter(nav => nav.bottom)
								.map(({path, bottom, ...props}) => (
									<NavIcon
										key={path}
										bottom={bottom}
										{...props}
										path={path}
										className={bottom && styles.bottom}
										onClick={closeMobileExpand}
										expanded={desktopExpanded}
									/>
								))}

							<Account desktopExpanded={desktopExpanded} switchRedirectModalOpen={open} />
						</div>
					</div>
				</div>
			</div>

			<MenuIcon className={styles.menu} onClick={toggleMobileExpand} open={mobileExpanded} />
		</div>
	);
};
