import {useQuery} from "@apollo/client";
import {FC, useCallback, useMemo, useState} from "react";
import classnames from "classnames";

import {Button, InputRow, Option, Select} from "../../components/input";
import {Span, Span4} from "../../components/text";
import {Card} from "../../components/card";
import {Loading} from "../../components/loading";
import {FeedPost, GET_MY_HUB, GET_YOUR_RECENT_SHARES_COUNT} from "../../data/feed";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {OpengraphMedia} from "../../components/opengraph";
import {FindOutWhoModal} from "./find-out-who-modal";
import {useModal} from "../../modals/new";

import styles from "./personal.module.scss";

const LIMIT = 5;

const filterOptions: Option<string>[] = [
	{label: "Most Recent", value: "newest"},
	{label: "Most Popular", value: "clicks"},
	{label: "Oldest", value: "oldest"},
];

const PostRow: FC<{post: FeedPost}> = ({post}) => {
	const {open, modal} = useModal({});
	return (
		<Card key={post.id} className={styles.topPost}>
			<div className={styles.postContent}>
				<OpengraphMedia className={styles.openGraphMedia} openGraph={post.item} />
				<div className={styles.text}>
					<Span bold href={post.url ?? undefined}>
						{post.item.title}
					</Span>
					{post.item.description && <Span4 trim={2}>{post.item.description}</Span4>}
					{post.sharedAt && <Span4>Posted At: {new Date(post.sharedAt.toString()).toLocaleString()}</Span4>}
				</div>
			</div>
			<div className={styles.postStats}>
				<div className={styles.stat}>
					<Span color="blue">{post.clickCount}</Span>
					<Span color="grey">Clicks</Span>
				</div>
				<div className={styles.stat}>
					<Span color="blue">${post.emv}</Span>
					<Span color="grey">EMV</Span>
				</div>
				<div className={styles.stat}>
					{!!post.visitors?.length && (
						<Span color="blue" onClick={open} bold>
							Find out Who
						</Span>
					)}
				</div>
			</div>
			<FindOutWhoModal post={post} modal={modal} />
		</Card>
	);
};

const renderItem = post => <PostRow key={post.id} post={post} />;

const ListPageControl: FC<{
	pageSize: number;
	pageNumber: number;
	total: number;
	hasMore: boolean;
	loading: boolean;
	onChange: (increment: number) => void;
}> = ({pageSize, pageNumber, total, hasMore, loading, onChange}) => (
	<InputRow className={styles.pageControl}>
		<Button onClick={() => onChange(-1)} icon="caret-left" disabled={pageNumber === 1} />
		<Span color="grey">
			Displaying {Math.min(total, pageSize * (pageNumber - 1) + 1)}-{Math.min(total, pageNumber * pageSize)}{" "}
			of {total}
		</Span>
		<Button onClick={() => onChange(1)} icon="caret-right" disabled={!hasMore || loading} />
	</InputRow>
);

const FilterSelector: FC<{value: string; onChange: (value: string) => void}> = ({value, onChange}) => (
	<InputRow position="right">
		<Select options={filterOptions} value={value} onChange={onChange} />
	</InputRow>
);

export const YourRecentShares: FC = () => {
	const [filter, setFilter] = useState<{sort: string}>({sort: "newest"});
	const [pageNumber, setPageNumber] = useState(1);

	const variables = useMemo(
		() => ({
			sort: filter.sort.toUpperCase(),
			type: ["shared".toUpperCase()],
			limit: LIMIT,
		}),
		[filter]
	);

	const {data = [], fetchMore, cursor, loading} = usePaginatedQuery<FeedPost>(GET_MY_HUB, {
		loadingProps,
		variables,
		renderItem,
	});

	const {data: countData} = useQuery(GET_YOUR_RECENT_SHARES_COUNT);
	const totalRows = countData?.yourRecentSharesCount ?? data.length;

	const hasMore = pageNumber * LIMIT < totalRows || !!cursor;

	const onPageChange = useCallback(
		(increment: number) => {
			setPageNumber(prev => prev + increment);
			if ((pageNumber + increment) * LIMIT > data.length && cursor) {
				fetchMore({variables: {cursor}});
			}
		},
		[fetchMore, cursor, pageNumber, data.length]
	);

	const displayedPosts = useMemo(
		() => data.slice((pageNumber - 1) * LIMIT, Math.min(totalRows, pageNumber * LIMIT)),
		[data, pageNumber, totalRows]
	);

	const onSortChange = useCallback((value: string) => {
		setPageNumber(1);
		setFilter({sort: value});
	}, []);

	return (
		<>
			<div className={classnames(styles.buttonsContainer, "space")}>
				<ListPageControl
					pageSize={LIMIT}
					pageNumber={pageNumber}
					total={totalRows}
					hasMore={hasMore}
					loading={loading}
					onChange={onPageChange}
				/>
				<FilterSelector value={filter.sort} onChange={onSortChange} />
			</div>

			{displayedPosts.length > 0
				? displayedPosts.map(renderItem)
				: !loading && (
						<InputRow position="center" className={styles.emptyMsg}>
							<Span>No shares found.</Span>
						</InputRow>
				  )}

			{loading && <Loading position="center" className={styles.loader} />}

			<div className={classnames(styles.buttonsContainer, "space")}>
				<ListPageControl
					pageSize={LIMIT}
					pageNumber={pageNumber}
					total={totalRows}
					hasMore={hasMore}
					loading={loading}
					onChange={onPageChange}
				/>
			</div>
		</>
	);
};
