import dayjs from "dayjs";
import {FC, useCallback, useMemo, useState} from "react";

import {FeedPost} from "../../data/feed";
import {Modal, ModalData} from "../../modals/new";
import {Button, InputRow, Text} from "../../components/input";
import {downloadCsv} from "../../utils/csv";
import {Span} from "../../components/text";
import {EmptyList} from "../../components/empty-list";

import styles from "./employee-advocacy/pending-users-modal.module.scss";

export const FindOutWhoModal: FC<{post: FeedPost; modal: ModalData}> = ({post, modal}) => {
	const [search, setSearch] = useState("");
	const csvData = useMemo(() => {
		const headers = ["Company", "Website", "Location", "Employees", "Contact", "Date Visited", "Clicks"];
		const searchTrimmed = search.trim();
		const searchLC = search.toLowerCase();
		const visitors = post.visitors ?? [];
		const items = visitors
			.filter(visitor => !searchTrimmed || visitor?.name?.toLowerCase()?.includes(searchLC))
			.map(visitor => [
				visitor.name || "-",
				visitor.url || "-",
				visitor.city || "-",
				visitor.employees || "-",
				visitor.email || "-",
				visitor.dateVisited ? dayjs(visitor.dateVisited).formatAs("shortDate") : "-",
				visitor.clicks || "-",
			]);
		return [headers, ...items];
	}, [post.visitors, search]);
	const handleExport = useCallback(() => {
		downloadCsv(csvData.slice(1), csvData[0], "find-out-who.csv");
	}, [csvData]);

	if (!modal.open) return null;

	return (
		<Modal modal={modal} title="Find Out Who" size="fit-content" className={styles.pendingUsersModal}>
			<div className={styles.content}>
				<InputRow className={styles.toolbar}>
					<div className="space">
						<Span>{post.clickCount} Clicks</Span>
					</div>
					<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
					<Button onClick={handleExport} value="Export CSV" />
				</InputRow>
				<div className={styles.items}>
					{csvData.map((row, rowIndex) => (
						<div className={styles.row} key={rowIndex}>
							{row.map((rowItem, rowItemIndex) => (
								<Span key={rowItemIndex} bold={rowIndex === 0} className={styles.rowItem}>
									{rowItem}
								</Span>
							))}
						</div>
					))}
					{!csvData.length && <EmptyList text={search ? "No matches found" : "No items"} />}
				</div>
			</div>
		</Modal>
	);
};
