import {FC, useMemo, useState} from "react";
import classnames from "classnames";

import {count} from "../../../utils/text";
import {Loading} from "../../../components/loading";
import {EmptyList} from "../../../components/empty-list";
import {EmptyComponent} from "../../../types";
import {InputRow, Text} from "../../../components/input";
import {Span, Span4} from "../../../components/text";
import {Card} from "../../../components/card";
import {OpengraphMedia} from "../../../components/opengraph";
import {Service, ShareEventAnalytics, accountNames, useCompanyList} from "../../../data";

import styles from "./post-stats.module.scss";

export interface Stat {
	value: string;
	format?: (v: number) => string;
	label: string;
}

export interface PostStatsProps extends EmptyComponent {
	account: Service;
	shareEvents: ShareEventAnalytics[];
	stats: Stat[];
	loading: boolean;
}

const normalizeApostrophes = text => text.replace(/’/g, "'");

export const PostStats: FC<PostStatsProps> = ({account, className, shareEvents, stats, loading}) => {
	const [search, setSearch] = useState("");
	const companies = useCompanyList();

	const filteredShareEvents = useMemo(() => {
		const searchTrimmed = normalizeApostrophes(search.trim());
		const searchLC = searchTrimmed.toLowerCase();

		if (!searchTrimmed) return shareEvents;

		return shareEvents.filter(({shareEvent}) => {
			const {title, description} =
				shareEvent?.share?.opengraphs?.[account] || shareEvent?.share?.opengraphs?.general || {};
			const normalizedTitle = normalizeApostrophes(title?.toLowerCase() || "");
			const normalizedDescription = normalizeApostrophes(description?.toLowerCase() || "");
			return normalizedTitle.includes(searchLC) || normalizedDescription.includes(searchLC);
		});
	}, [search, shareEvents, account]);

	const isEmpty = filteredShareEvents.length === 0;

	const orderedShareEvents = useMemo(
		() =>
			filteredShareEvents.sort((a, b) => {
				const dateA = a.shareEvent.sharedAt ? a.shareEvent.sharedAt.toDate().getTime() : 0; // Use 0 for undefined dates
				const dateB = b.shareEvent.sharedAt ? b.shareEvent.sharedAt.toDate().getTime() : 0; // Use 0 for undefined dates
				return dateB - dateA; // Sort descending
			}),
		[filteredShareEvents]
	);

	return (
		<div className={classnames(className, "space")}>
			<InputRow className={styles.header}>
				<div className={styles.text}>
					<h3>{accountNames[account]} Post Breakdown </h3>
					<Span size={1}>{count(shareEvents, "Post")}</Span>
				</div>

				{shareEvents.length > 0 && (
					<Text icon="search" value={search} onChange={setSearch} placeholder="Search posts" />
				)}
			</InputRow>

			{loading ? (
				<Loading />
			) : (
				<>
					{orderedShareEvents.map(({shareEvent, ...seStats}) => {
						const opengraph =
							shareEvent?.share?.opengraphs?.[account] || shareEvent?.share?.opengraphs?.general || {};

						return (
							<Card key={shareEvent.id} className={styles.topPost}>
								<div className={styles.postContent}>
									<OpengraphMedia className={styles.openGraphMedia} openGraph={opengraph} showDefaultImage />
									<div className={styles.text}>
										<Span4 bold>{companies.find(c => c.value === shareEvent.share.companyId)?.label}</Span4>
										<Span bold href={shareEvent.share.url ?? undefined}>
											{opengraph.title}
										</Span>
										{shareEvent.share.opengraphs.general.description && (
											<Span4 trim={2}>{opengraph.description}</Span4>
										)}
										<Span4>Post date: {shareEvent.sharedAt?.formatAs("numDate")}</Span4>
									</div>
								</div>
								<div className={styles.postStats}>
									{stats.map(({format = v => v, label, value}) => (
										<div key={value} className={styles.stat}>
											<Span color="blue">{format(seStats[value])}</Span>
											<Span color="grey">{label}</Span>
										</div>
									))}
								</div>
							</Card>
						);
					})}
					{isEmpty && <EmptyList text={search ? "No matches found" : "No items"} />}
				</>
			)}
		</div>
	);
};
