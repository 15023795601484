import {useCallback, useMemo, useRef, useState} from "react";
import {Formik} from "formik";
import dayjs from "dayjs";
import {useLocalStorage} from "react-use";
import isEqual from "lodash/isEqual";

import {CreatePostForm} from "./form/create-post-form";
import {validationSchema} from "./form/validation-schema";
import {
	CREATE_COMPANY_SHARE,
	OpenGraph,
	Service,
	services,
	Share,
	SHARE_IMMEDIATELY,
	UPDATE_SHARE,
	UPDATE_SHARE_EVENT,
	useMyUser,
} from "../../../data";
import {useMutationToast} from "../../../toast";
import {useCompanyPages} from "../../../data/company";
import {EditPostForm} from "./form/edit-post-form";
import {SharingResults} from "../components";
import {FormValues, Schedule} from "./form/types";
import {UserShareEventsResult} from "../../../data/share";
import {useNewModal as useModal} from "../../../modals";
import {REGISTER_PAGE_EVENT} from "../../../data/badges";
import {Setter} from "../../../types";
import {postCookieDeserializer} from "./personal-post";

interface CompanyPostProps {
	share: Share;
	shareFromUrl?: string;
	setShareFromUrl: Setter<string | undefined>;
	onComplete: () => void;
	disabled?: boolean;
}
export const CompanyPost = ({
	share,
	shareFromUrl,
	setShareFromUrl,
	onComplete,
	disabled,
}: CompanyPostProps) => {
	const me = useMyUser();

	const {id, opengraphs, url} = share ?? {};
	const events = useMemo(
		() =>
			share.shareEvents.filter(
				se => !se.scheduledFor || se.scheduledFor.isAfter() || !se.sharedAt || (se.sharedAt && !se.result)
			),
		[share.shareEvents]
	);
	const shareNetworks = useMemo(() => events.map(ev => ev.network), [events]);
	const companies = useCompanyPages();
	const availableNetworks = useMemo(
		() =>
			companies.reduce(
				(acc, c) => ({
					...acc,
					[c.id]: services.filter(s => c.connections[s]?.connected),
				}),
				{}
			),
		[companies]
	);
	const [newPostCookie, setNewPost, remove] = useLocalStorage(`new-post-company-${me.id}`, null, {
		raw: false,
		serializer: JSON.stringify,
		deserializer: postCookieDeserializer(availableNetworks),
	});

	const initialNewPostCookie = useRef(newPostCookie);

	const recipientOptions = useMemo(
		() =>
			companies.map(c => ({
				label: c.name,
				id: String(c.id),
				networks: availableNetworks[c.id],
				peakTime: c.peakTime,
				schedule: availableNetworks[c.id].reduce(
					(acc, n) => ({
						...acc,
						[n]: {
							id: events.find(ev => ev.network === n)?.id,
							scheduledFor: undefined,
							peakTime: c.peakTime,
							timeslot: !c.peakTime,
						},
					}),
					{}
				),
			})),
		[companies, availableNetworks, events]
	);
	const [results, setResults] = useState<UserShareEventsResult | undefined>();
	const {modal: shareResultsModal, open: openShareResultsModal, close: closeShareResultsModal} = useModal({});
	const [createCompanyShare, {loading: creating}] = useMutationToast(CREATE_COMPANY_SHARE);
	const [updateShare, {loading: saving}] = useMutationToast(UPDATE_SHARE);
	const [updateShareEvent, {loading: updating}] = useMutationToast(UPDATE_SHARE_EVENT);
	const [shareImmediately, {loading: sharing}] = useMutationToast(SHARE_IMMEDIATELY);
	const [registerPageEvent] = useMutationToast(REGISTER_PAGE_EVENT);
	const createPost = useCallback(
		async (values: FormValues) => {
			const {opengraphs, recipient, schedule, url} = values;

			const result = await createCompanyShare({
				variables: {
					companyId: recipient.id,
					networks: recipient.networks.map(n => ({
						network: n,
						scheduledFor: schedule.immediately
							? dayjs().add(5, "minutes")
							: schedule?.[n]?.scheduledFor ?? new Date(),
						peakTime: !schedule.immediately && !schedule?.[n]?.scheduledFor && schedule?.[n]?.peakTime,
					})),
					url,
					opengraphs: Object.keys(opengraphs).reduce((acc, n) => {
						if (opengraphs[n]) {
							acc[n] = opengraphs[n];
						}

						return acc;
					}, {}),
					timezone: dayjs.tz.guess(),
				},
			});

			if (url) {
				registerPageEvent({
					variables: {
						type: "pastelink",
						userId: recipient.id,
					},
				});
			}

			return Promise.resolve(result?.data?.createCompanyShare);
		},
		[createCompanyShare, registerPageEvent]
	);

	const updateShareEvents = useCallback(
		async values => {
			const {schedule} = values;
			const promises = [] as Promise<object>[];

			Object.keys(schedule ?? {}).forEach(network => {
				const ev = events.find(ev => ev.network === network);
				if (!ev?.id || dayjs(schedule[network].scheduledFor).isSame(ev.scheduledFor)) return;
				promises.push(
					updateShareEvent({
						variables: {
							id: ev.id,
							scheduledFor: schedule[network].scheduledFor,
						},
					})
				);
			});

			return Promise.all(promises);
		},
		[updateShareEvent, events]
	);
	const maybeUpdatePost = useCallback(
		async (values: FormValues) => {
			const {opengraphs, perNetwork} = values;
			const changes = {} as {opengraphs?: Record<Service, OpenGraph>; url?: string | null};
			const deletions = {} as {opengraphs?: Record<Service, boolean>};

			const updatedOpengraphs = Object.keys(opengraphs).reduce((acc, n) => {
				if (
					Object.entries(opengraphs[n] ?? {}).some(([property, v]) => share.opengraphs[n]?.[property] !== v)
				) {
					acc[n] = {
						...opengraphs[n],
						...((opengraphs[n].image || opengraphs[n].video) &&
						["Text Share", "Image Share", "Video Share", ""].includes(opengraphs[n]?.title ?? "")
							? {title: `${opengraphs[n].video ? "Video" : "Image"} Share`}
							: {}),
					};
				}

				return acc;
			}, {} as Record<Service, OpenGraph>);

			if (Object.keys(updatedOpengraphs).length) {
				changes.opengraphs = updatedOpengraphs;
			}

			const deletedOpengraphs = (perNetwork
				? (Object.keys(opengraphs) as Service[])
						.filter(n => services.includes(n) && share.opengraphs[n] && !opengraphs[n])
						.reduce((acc, n) => ({...acc, [n]: true}), {})
				: {}) as Record<Service, boolean>;

			if (Object.keys(deletedOpengraphs).length) {
				deletions.opengraphs = deletedOpengraphs;
			}

			if (!Object.keys(changes).length && !Object.keys(deletions).length && values.url === share.url) {
				return share;
			}

			const result = await updateShare({
				variables: {
					id: share.id,
					changes,
					deletions,
					...(values.url !== share.url ? {url: values.url} : {}),
				},
			});

			return result?.data?.updateShare;
		},
		[updateShare, share]
	);

	const update = useCallback(
		async values => {
			await updateShareEvents(values);

			return maybeUpdatePost(values);
		},
		[maybeUpdatePost, updateShareEvents]
	);

	const maybeShareImmediately = useCallback(
		async (values, share) => {
			if (!values.schedule.immediately || !share?.id) {
				return Promise.resolve();
			}

			const result = await shareImmediately({variables: {id: share.id}});

			setResults(result?.data?.shareImmediately);
			openShareResultsModal();
		},
		[shareImmediately, openShareResultsModal]
	);

	const onSubmit = useCallback(
		async (values: FormValues, {setSubmitting}) => {
			setSubmitting(true);

			let result;

			if (share.id) {
				result = await update(values);
			} else {
				result = await createPost(values);
			}

			await maybeShareImmediately(values, result);

			setSubmitting(false);
			remove();
		},
		[createPost, update, share, maybeShareImmediately, remove]
	);

	const socialScoreValue = useMemo(
		() => share.shareEvents.filter(se => !se.sharedAt).reduce((acc, item) => acc + item.socialScore, 0),
		[share.shareEvents]
	);
	const socialScoreSuggestion = useMemo(() => share.smartScoreSuggestions?.general[0]?.message, [
		share.smartScoreSuggestions,
	]);
	const potentialSocialScore = useMemo(() => {
		const potential =
			socialScoreValue + share.smartScoreSuggestions?.general.reduce((acc, item) => acc + item.value, 0);
		return (socialScoreValue / potential || 0) * 100;
	}, [socialScoreValue, share.smartScoreSuggestions?.general]);
	const perNetwork = services.some(s => share.opengraphs[s]);
	const emptyValues = useMemo(
		() => ({
			id: undefined,
			opengraphs: {
				general: {
					comment: "",
				},
			},
			url: undefined,
			perNetwork: false,
			activeNetwork: perNetwork ? services.find(s => !!share.opengraphs[s]) : "general",
			recipient: null,
			schedule: {},
		}),
		[perNetwork, share.opengraphs]
	);

	const initialValues = useMemo(
		() =>
			({
				id,
				opengraphs,
				url,
				perNetwork,
				activeNetwork: perNetwork ? services.find(s => !!share.opengraphs[s]) : "general",
				recipient: share.id
					? {
							id: String(share.companyId),
							networks: share.id ? shareNetworks : availableNetworks,
					  }
					: null,
				schedule: events.reduce(
					(acc, ev) => ({
						...acc,
						[ev.network]: {
							id: ev.id,
							scheduledFor: ev.scheduledFor,
						},
					}),
					{} as Schedule
				),
			} as FormValues),
		[id, opengraphs, url, perNetwork, share, shareNetworks, availableNetworks, events]
	);
	const isEmpty = isEqual(initialValues, emptyValues);
	const formProps = useMemo(
		() => ({
			recipientOptions,
			disabled: disabled || creating || saving || updating || sharing,
			socialScore: {
				value: socialScoreValue,
				potential: potentialSocialScore,
				suggestion: socialScoreSuggestion,
			},
			shareFromUrl,
			setShareFromUrl,
			onClose: () => {
				remove();
				onComplete();
			},
		}),
		[
			recipientOptions,
			disabled,
			creating,
			saving,
			updating,
			sharing,
			socialScoreValue,
			potentialSocialScore,
			socialScoreSuggestion,
			shareFromUrl,
			setShareFromUrl,
			onComplete,
			remove,
		]
	);

	const onSharingResultsClose = useCallback(() => {
		closeShareResultsModal();
		onComplete();
	}, [closeShareResultsModal, onComplete]);

	const initialFormValues = useMemo(
		() =>
			id
				? initialValues
				: initialNewPostCookie.current && isEmpty
				? initialNewPostCookie.current
				: initialValues,
		[id, initialValues, isEmpty]
	);
	return (
		<>
			<Formik
				initialValues={initialFormValues}
				validationSchema={validationSchema}
				validateOnChange
				validateOnMount
				onSubmit={onSubmit}
			>
				{share?.id ? (
					<EditPostForm share={share} {...formProps} />
				) : (
					<CreatePostForm
						initialValues={initialValues}
						hasUnsavedChanges={!!newPostCookie && !isEqual({...initialNewPostCookie.current}, initialValues)}
						setCachePost={setNewPost}
						{...formProps}
					/>
				)}
			</Formik>
			<SharingResults modal={shareResultsModal} onClose={onSharingResultsClose} results={results} />
		</>
	);
};
