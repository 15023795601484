import React, {FC, useCallback, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {Dayjs} from "dayjs";

import {
	Button,
	CalendarButton,
	EmojiPickerInput,
	InputRow,
	Separator,
	Text,
	SmallButton,
	useValidate,
} from "../../components/input";
import {OpenGraph, Service, UPLOAD_IMAGE, useMyUser} from "../../data";
import {PostServiceType} from "../../data/collection";
import {useMutationToast, useToast} from "../../toast";
import {Span, Span3} from "../../components/text";
import {Arrow, Icon} from "../../components/images";
import {OpengraphMedia} from "../../components/opengraph";
import {EmptyComponent} from "../../types";
import {Media} from "../../data/media";
import {Loading} from "../../components/loading";
import {MentionsText} from "../../components/input/mentions-text";
import {AITextPicker} from "../../components/input/ai-text-picker";
import {networkMaxCharacters, networkMaxImages} from "./index";
import {renderMentions} from "../../utils/text";
import {useCanvaDesign} from "../../hooks/use-canva-design";
import {MediaButton} from "../../components/media/media-button";
import {useConfirmModal} from "../../modals";

import styles from "./post.module.scss";

interface BaseOGEditorProps extends EmptyComponent {
	opengraph: OpenGraph;
	url: string | null | undefined;
	updateOpengraph: (changes: Partial<OpenGraph>) => void;
	isURL: boolean;
	startOpen?: boolean;
	placeholder?: string;
	peakTime?: boolean;
	isLoading?: boolean;
	onRemove?: () => void;
	displayRemoveIcon?: boolean;
	showEditButton?: boolean;
}

interface BulkEditorProps extends BaseOGEditorProps {
	schedule: () => void;
	network?: never;
	setScheduledFor?: never;
	scheduledFor?: never;
	scheduling?: never;
	disabled?: boolean;
	hasErrors?: boolean;
}

interface ScheduleEditorProps extends BaseOGEditorProps {
	schedule?: never;
	network: Service;
	setScheduledFor: (value: Dayjs | undefined) => void;
	scheduledFor?: Dayjs;
	scheduling?: boolean;
	disabled?: boolean;
	hasErrors?: boolean;
}

export const OGEditor: FC<BulkEditorProps | ScheduleEditorProps> = ({
	isURL,
	isLoading,
	url,
	displayRemoveIcon,
	onRemove,
	network,
	opengraph,
	schedule,
	scheduling,
	scheduledFor,
	setScheduledFor,
	startOpen,
	updateOpengraph,
	placeholder,
	disabled,
	hasErrors,
	peakTime,
	showEditButton,
}) => {
	const [open, setOpen] = useState(startOpen);
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const me = useMyUser();
	const toast = useToast();
	const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
	const [uploadImage, {loading}] = useMutationToast(UPLOAD_IMAGE);
	const multiImage = useMemo(() => !isURL && opengraph.image && !opengraph.video, [
		opengraph.image,
		opengraph.video,
		isURL,
	]);
	const handleMediaUpdate = useCallback(
		(selectedMedia: Media[]) => {
			if (isURL || opengraph.video || selectedMedia[0]?.type === "video") {
				updateOpengraph({
					image: selectedMedia[0]?.type === "image" ? selectedMedia[0].url : undefined,
					video: selectedMedia[0]?.type === "video" ? selectedMedia[0].url : undefined,
				});
				return;
			}

			const newImages = selectedMedia.filter(m => m.type === "image").map(m => m.url);

			if (!newImages.length) return;

			const allImages = [
				...(opengraph.image ? [opengraph.image] : []),
				...(opengraph.images ?? []),
				...newImages,
			].slice(0, networkMaxImages[network || "general"]);

			updateOpengraph({image: allImages[0]});
			updateOpengraph({
				images: allImages.slice(1),
			});
		},
		[updateOpengraph, opengraph, network, isURL]
	);

	const hasUrl = useMemo(() => isURL || url, [isURL, url]);
	const tabs = useMemo<("image" | "video")[]>(() => (hasUrl || multiImage ? ["image"] : ["image", "video"]), [
		hasUrl,
		multiImage,
	]);

	const onFileChange = useCallback(
		async (files: File[]) => {
			if (!files) return;

			const imgFiles = files.filter(file => file.type.split("/")?.[0] === "image");

			if (!imgFiles.length) {
				return;
			}

			const existingImages = [...(opengraph.image ? [opengraph.image] : []), ...(opengraph.images ?? [])];

			const results = await Promise.all(
				imgFiles
					.slice(0, networkMaxImages[network || "general"] - existingImages.length)
					.map(file => uploadImage({variables: {file}}))
			).catch(errors => toast({color: "red", text: errors[0], icon: "warning"}));

			if (!results) return;

			const newImages = results.map(({data}) => data?.uploadImage);

			if (url) {
				updateOpengraph({image: newImages[0]});
				updateOpengraph({images: undefined});
				return;
			}

			const allImages = [...existingImages, ...newImages].slice(0, networkMaxImages[network || "general"]);

			updateOpengraph({image: allImages[0]});
			updateOpengraph({images: allImages.slice(1)});
		},
		[toast, updateOpengraph, uploadImage, opengraph, network, url]
	);

	const handleEmojiChange = (emoji: string) => {
		const pos = textareaRef?.current?.selectionStart ?? 0;
		const comment = opengraph.comment ?? "";

		updateOpengraph({comment: `${comment.slice(0, pos)}${emoji}${comment.slice(pos)}`});
		const focusInput = () => {
			textareaRef?.current?.focus();
			textareaRef?.current?.setSelectionRange(pos + emoji.length, pos + emoji.length);
		};

		setTimeout(focusInput, 0);
	};

	const handleCommentChange = useCallback(
		comment => {
			updateOpengraph({comment});
		},
		[updateOpengraph]
	);
	const handleTitleChange = useCallback(title => updateOpengraph({title}), [updateOpengraph]);
	const handleDescriptionChange = useCallback(description => updateOpengraph({description}), [
		updateOpengraph,
	]);
	const hasOgMetadata = useMemo(
		() => isURL || (opengraph.video && (network === undefined || network === "linkedin")),
		[isURL, opengraph.video, network]
	);
	const {error} = useValidate(`${network}-comment`, opengraph.comment, {
		immediate: true,
		check: () =>
			!opengraph.comment && !isURL
				? "Comment is required"
				: (opengraph?.comment?.length ?? 0) > networkMaxCharacters[network || "general"]
				? "Caption is too long"
				: "",
	});

	const onCanvaFileSelect = useCallback(
		(url: string) => {
			if (!opengraph.image || !multiImage) {
				updateOpengraph({image: url});
			} else {
				updateOpengraph({images: [...(opengraph.images ?? []), url]});
			}
		},
		[updateOpengraph, opengraph, multiImage]
	);
	const {loading: loadingCanva, createDesignJob} = useCanvaDesign({
		onCompleted: onCanvaFileSelect,
	});
	const onCanvaItemSelect = useCallback(
		designId => {
			createDesignJob(designId);
		},
		[createDesignJob]
	);

	const handleRemoveMultiImage = useCallback(() => {
		const images = [opengraph.image, ...(opengraph.images ?? [])].filter(
			(img, i) => img && i !== selectedImageIndex
		) as string[];
		updateOpengraph({image: images[0] || null, images: images.slice(1)});
	}, [updateOpengraph, opengraph, selectedImageIndex]);

	const {open: openConfirmRemoveImage} = useConfirmModal(
		() => ({
			title: "Remove selected image?",
			body: <div>Are you sure you want to delete the selected image?</div>,
			confirmText: "Confirm",
			onConfirm: close => {
				handleRemoveMultiImage();
				close();
			},
		}),
		[handleRemoveMultiImage]
	);

	const onRemoveMultiImage = useCallback(
		(index: number) => {
			setSelectedImageIndex(index);
			openConfirmRemoveImage();
		},
		[setSelectedImageIndex, openConfirmRemoveImage]
	);

	const opengraphImages = useMemo(
		() => [opengraph.image, ...(opengraph.images ?? [])].slice(0, networkMaxImages[network || "general"]),
		[opengraph.image, opengraph.images, network]
	);

	return (
		<div className={classnames(styles.postItem, "space", (hasErrors || error) && styles.postError)}>
			<div className={classnames(styles.postHeader, "space")}>
				{network ? (
					<Icon className={classnames(open ? styles.tileElementOpen : null)} icon={network} />
				) : (
					<div />
				)}
				{open ? (
					<MentionsText
						ref={textareaRef}
						type="textarea"
						placeholder="Write a comment or paste a link to share"
						onChange={handleCommentChange}
						value={opengraph.comment}
						bare
						service={PostServiceType[network || "general"]}
						disabled={disabled || isLoading}
						characterCount
						maxLength={networkMaxCharacters[network || "general"]}
						error={error}
					/>
				) : (
					<div className={styles.previewContainer}>
						<OpengraphMedia openGraph={opengraph} width={80} height={40} />
						<Span3 trim={1}>
							{isURL ? opengraph.title : renderMentions(opengraph.comment) || placeholder}
						</Span3>
						{showEditButton && (
							<div className={styles.buttonsContainer}>
								{scheduledFor && (
									<div className={styles.scheduledForContainer}>
										<Span bold>Scheduled For</Span>
										<CalendarButton
											value={scheduledFor}
											onChange={setScheduledFor}
											color="black"
											invert
											border={false}
											icon="schedule"
											loading={scheduling}
											disabled={disabled}
										/>
									</div>
								)}
								<div className={styles.editButton}>
									<SmallButton
										invert
										border={false}
										value="Edit Post"
										arrow="down"
										onClick={() => setOpen(c => !c)}
									/>
								</div>
							</div>
						)}
						{scheduledFor && !showEditButton && (
							<CalendarButton
								value={scheduledFor}
								onChange={setScheduledFor}
								color="black"
								icon="schedule"
								loading={scheduling}
								disabled={disabled}
								border={false}
								invert
							/>
						)}
					</div>
				)}
				{(!showEditButton || open) && (
					<Arrow
						className={classnames(open ? styles.tileElementOpen : null)}
						direction={open ? "up" : "down"}
						onClick={() => setOpen(c => !c)}
					/>
				)}
			</div>
			{open && (
				<div className="space">
					{multiImage ? (
						<div className={styles.multiImage}>
							<div className={styles.imagesCt}>
								{opengraphImages.map((image, index) => (
									<div key={index} className={styles.multiImageItem}>
										<OpengraphMedia
											showControls
											className={isURL && styles.ogMediaCut}
											openGraph={{image}}
											width={96}
											height={96}
										/>
										<div className={styles.removeBtn} onClick={() => onRemoveMultiImage(index)}>
											<Icon icon="close" width={24} color="white" />
										</div>
									</div>
								))}
								<MediaButton
									ButtonComponent={({isOpen}) => (
										<div
											className={classnames(styles.addImageBtn, {
												[styles.active]: isOpen,
												[styles.disabled]: opengraphImages.length >= networkMaxImages[network || "general"],
											})}
										>
											<div className={styles.icon}>
												<Icon icon="add" color="white" width={16} height={16} viewBox={"0 0 24 24"} />
											</div>
											{(loading || loadingCanva) && <Loading size="small" className={styles.loading} />}
										</div>
									)}
									disabled={opengraphImages.length >= networkMaxImages[network || "general"]}
									dropdownPlacement={"right-start"}
									onFileChange={onFileChange}
									onCanvaSelect={onCanvaItemSelect}
									multiple={true}
									mediaLibrary={{
										onConfirm: handleMediaUpdate,
										tabs: ["image"],
										multiple: true,
									}}
								/>
							</div>
							{!network && (
								<div className={styles.multiImageInfo}>
									<Icon icon="information" width={20} height={20} color="blue" />
									<Span3 trim={2}>
										Certain social networks limit the number of images that can be added to a post.
									</Span3>
									<Span3 color="blue" bold href="https://cvssupport.wpenginepowered.com/">
										Learn more
									</Span3>
								</div>
							)}
						</div>
					) : (
						(isLoading || loadingCanva || loading || isURL || opengraph.image || opengraph.video) && (
							<div className={classnames(styles.ogEdit, "space", isLoading ? styles.loadingOgMedia : "")}>
								{isLoading || loadingCanva || loading ? (
									<Loading position="center" />
								) : (
									<OpengraphMedia
										openGraph={opengraph}
										showControls
										className={isURL && styles.ogMediaCut}
										width={450}
										height={"100%"}
									/>
								)}
								{displayRemoveIcon && <Icon icon="close" className={styles.closeIcon} onClick={onRemove} />}

								{hasOgMetadata && (
									<div className={styles.ogMetadataContainer}>
										{url && <div className={styles.ogMetadataUrl}>{url}</div>}
										<div className={styles.title}>
											<Text
												size={4}
												disabled={disabled}
												bold
												bare
												placeholder="Add a title."
												value={opengraph.title}
												onChange={handleTitleChange}
												characterCount={network === "linkedin"}
												maxLength={network ? (network === "linkedin" ? 70 : 100) : undefined}
												type="textarea"
												minRows={1}
											/>
											{me.org.options.ai && !disabled && (
												<AITextPicker
													value={opengraph.title || ""}
													label="Title"
													onChange={handleTitleChange}
													disabled={disabled}
													maxLength={100}
													className={styles.aiTextPicker}
												/>
											)}
										</div>
										{["general", "facebook"].includes(network ?? "general") && (
											<div className={styles.description}>
												<Text
													bare
													disabled={disabled}
													value={opengraph.description}
													type="textarea"
													minRows={3}
													placeholder="Add a description."
													characterCount
													onChange={handleDescriptionChange}
												/>
												{me.org.options.ai && !disabled && (
													<AITextPicker
														value={opengraph.description || ""}
														label="Description"
														onChange={handleDescriptionChange}
														disabled={disabled}
														maxLength={300}
														className={styles.aiTextPicker}
													/>
												)}
											</div>
										)}
									</div>
								)}
							</div>
						)
					)}
					<InputRow className={styles.footerToolbar}>
						<MediaButton
							onFileChange={onFileChange}
							loading={loading || loadingCanva}
							onCanvaSelect={onCanvaItemSelect}
							multiple={!isURL && !opengraph.video}
							mediaLibrary={{
								onConfirm: handleMediaUpdate,
								tabs: tabs,
								multiple: !isURL && !opengraph.video,
							}}
						/>
						<EmojiPickerInput value="" onChange={handleEmojiChange} disabled={disabled} />

						{me.org.options.ai && (
							<AITextPicker
								value={renderMentions(opengraph.comment) || opengraph.title || opengraph.description || ""}
								label="Comment"
								onChange={handleCommentChange}
								disabled={disabled}
							/>
						)}
						<Separator />
						{scheduledFor ? (
							<CalendarButton
								disabled={disabled}
								size="small"
								value={scheduledFor}
								onChange={setScheduledFor}
								color="black"
								icon="schedule"
								loading={scheduling}
								border={false}
								invert
							/>
						) : (
							schedule &&
							peakTime && (
								<Button
									disabled={disabled}
									invert
									border={false}
									icon="schedule"
									onClick={schedule}
									color="black"
									value="Schedule using Peaktime™"
								/>
							)
						)}
					</InputRow>
				</div>
			)}
		</div>
	);
};
