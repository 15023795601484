import {useCallback, useMemo, useRef, useState} from "react";
import {useFormikContext} from "formik";

import {BottomToolbar, NetworkToolbar} from "./toolbars";
import {
	Editor,
	networkCaptionMaxLength,
	networkDescriptionMaxLength,
	networkTitleMaxLength,
} from "../../components";
import {getURL, isURL} from "../../../../utils/text";
import {useCanvaDesign} from "../../../../hooks/use-canva-design";
import {clearTypename, OPENGRAPH_FROM_URL, useMyUser, services, UPLOAD_IMAGE} from "../../../../data";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";
import {useMutationToast} from "../../../../toast";
import {PostCollectionFormValues, OnChange} from "./post";
import {PostEditorHeader} from "./post-editor-header";
import {DragHandleProps} from "./drag-drop";

import styles from "./styles.module.scss";

interface PostEditorProps {
	onRemove?: () => void;
	dragHandleProps?: DragHandleProps;
}

const getOpengraphType = opengraph => (!!opengraph?.image || !!opengraph?.video ? "media" : "text");

export const PostEditor = ({onRemove, dragHandleProps}: PostEditorProps) => {
	const me = useMyUser();
	const aiEnabled = me.org.options.ai;
	const [opengraphFromURL, {loading: loadingURL}] = useMutationToast(OPENGRAPH_FROM_URL);
	const [uploadImage, {loading: uploading}] = useMutationToast(UPLOAD_IMAGE);
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const {
		values,
		errors,
		setFieldValue,
		submitForm,
		setFieldError,
	} = useFormikContext<PostCollectionFormValues>();
	const {url, opengraphs, activeNetwork, perNetwork} = values;
	const [optimizedForTwitter, setOptimizedForTwitter] = useState(false);
	const editorData = useMemo(() => opengraphs?.[activeNetwork] ?? opengraphs?.general, [
		opengraphs,
		activeNetwork,
	]);
	const title = editorData?.title;
	const comment = editorData?.comment ?? "";
	const description = editorData?.description;
	const image = editorData?.image;
	const images = editorData?.images;
	const video = editorData?.video;
	const {opengraphs: opengraphsErrors} = errors;
	const editorErrors = opengraphsErrors?.[activeNetwork] ?? opengraphsErrors?.general;
	const maxLength = optimizedForTwitter
		? networkCaptionMaxLength.twitter
		: networkCaptionMaxLength[activeNetwork];
	const editorErrorsMsg =
		typeof editorErrors === "object"
			? [
					editorErrors?.comment,
					editorErrors?.video,
					editorErrors?.image,
					editorErrors?.title,
					editorErrors?.description,
			  ]
					.filter(v => !!v)
					.map(error => (typeof error === "string" ? error : error?.[0] ?? ""))
			: [];
	const debouncedSubmit = useDebounceCallback(submitForm, 2000);
	const onChange = useCallback(
		(field, value) => {
			setFieldValue(field, value);

			debouncedSubmit();
		},
		[setFieldValue, debouncedSubmit]
	);

	const postType = useMemo(() => {
		if (url) return "url";

		const activeOpengraphs = Object.keys(opengraphs).filter(key =>
			perNetwork ? key !== "general" && opengraphs[key] : key === "general"
		);
		if (activeOpengraphs.length === 0) return getOpengraphType(opengraphs.general);
		const types = activeOpengraphs.map(key => getOpengraphType(opengraphs[key]));
		if (types.find(type => type === "media")) return "media";

		return "text";
	}, [url, opengraphs, perNetwork]);

	const handleOpengraphChange = useCallback(
		async value => {
			if (typeof value === "string" && isURL(value) && postType === "text") {
				const url = getURL(value);
				const {data} = await opengraphFromURL({variables: {url}});
				const opengraph = clearTypename(data?.opengraphFromURL);

				if (Object.keys(opengraph).length) {
					onChange(`opengraphs`, {
						general: {
							...opengraph,
							comment: "",
						},
						...services.reduce((acc, service) => {
							acc[service] = null;
							return acc;
						}, {}),
					});
					setFieldValue("url", url);
					return;
				}
			}

			if (!opengraphs[activeNetwork]) {
				setFieldValue(`opengraphs.${activeNetwork}`, {...opengraphs.general});
			}

			onChange(`opengraphs.${activeNetwork}.comment`, value);
		},
		[onChange, activeNetwork, opengraphs, setFieldValue, opengraphFromURL, postType]
	);

	const handleCommentChange = useCallback<OnChange>(
		async (field, value) => {
			let focus = false;
			const pos = textareaRef?.current?.selectionStart ?? 0;
			const focusInput = () => {
				textareaRef?.current?.focus();
				if (typeof value === "string") {
					textareaRef?.current?.setSelectionRange(pos + value.length, pos + value.length);
				}
			};

			if (field === "emoji") {
				value = `${comment.slice(0, pos)}${value}${comment.slice(pos)}`;
				focus = true;
			}

			await handleOpengraphChange(value);

			if (focus) {
				setTimeout(focusInput, 0);
			}
		},
		[comment, textareaRef, handleOpengraphChange]
	);

	const onEditorChange = useCallback<OnChange>(
		(field, value) => {
			if (field === "comment" || field === "emoji") {
				handleCommentChange(field, value);
				return;
			}

			if (!opengraphs[activeNetwork]) {
				setFieldValue(`opengraphs.${activeNetwork}`, {...opengraphs.general});
			}

			onChange(`opengraphs.${activeNetwork}.${field}`, value);
		},
		[onChange, activeNetwork, opengraphs, setFieldValue, handleCommentChange]
	);

	const onClear = useCallback(
		async field => {
			if (field === "comment") {
				onEditorChange(field, "");
			}

			if (field === "url") {
				await onChange("url", null);
				onChange(`opengraphs`, {
					general: {
						comment: opengraphs.general.comment,
						description: "",
						image: null,
						title: "",
						video: null,
					},
				});
				return;
			}
		},
		[onEditorChange, onChange, opengraphs]
	);

	const onCanvaFileSelect = useCallback(
		(url: string) => {
			onEditorChange("image", url);
		},
		[onEditorChange]
	);
	const {createDesignJob, loading: loadingCanvaMedia} = useCanvaDesign({
		onCompleted: onCanvaFileSelect,
	});

	const multiImage = postType === "media" && !video;

	return (
		<Editor
			className={styles.editor}
			title={title}
			comment={comment}
			video={video}
			image={image}
			images={images}
			url={url}
			description={description}
			activeNetwork={activeNetwork}
			multiImage={multiImage}
			onChange={onEditorChange}
			onClear={onClear}
			textareaRef={textareaRef}
			maxLength={maxLength}
			titleMaxLength={networkTitleMaxLength[optimizedForTwitter ? "twitter" : activeNetwork]}
			descriptionMaxLength={networkDescriptionMaxLength[optimizedForTwitter ? "twitter" : activeNetwork]}
			loading={loadingURL}
			loadingMedia={uploading || loadingCanvaMedia}
			Header={<PostEditorHeader onChange={onChange} dragHandleProps={dragHandleProps} />}
			TopToolbar={perNetwork && <NetworkToolbar onChange={onChange} />}
			BottomToolbar={
				<BottomToolbar
					comment={comment}
					image={image || ""}
					images={images}
					multiImage={multiImage}
					createDesignJob={createDesignJob}
					uploadImage={uploadImage}
					onChange={onChange}
					onOpengraphChange={onEditorChange}
					onDelete={onRemove}
					optimizedForTwitter={optimizedForTwitter}
					maxLength={maxLength}
					setOptimizedForTwitter={value => {
						setOptimizedForTwitter(value);
						setFieldError(
							`opengraphs.${activeNetwork}.comment`,
							value && (comment?.length || 0) > networkCaptionMaxLength.twitter
								? "Caption too long"
								: undefined
						);
					}}
				/>
			}
			aiEnabled={aiEnabled}
			showDescription={["general", "facebook"].includes(activeNetwork)}
			errors={editorErrorsMsg}
		/>
	);
};
