import {FC, ReactElement, ReactNode, useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Card, CollapsibleCard, CollapsibleCardProps} from "../../components/card";
import {useMyUser} from "../../data";
import {P2, Span} from "../../components/text";
import {EmptyComponent} from "../../types";
import {
	Button,
	DropdownButton,
	InputRow,
	MultiColumn,
	Separator,
	SmallButton,
	Text,
} from "../../components/input";
import {useConfirmModal, useModal, useNewModal} from "../../modals";
import {useMutationToast} from "../../toast";
import {useAddToCollectionModal} from "../collections/add-to-collection";
import {FeedPost as FeedPostType, HIDE_FEED_POST, HIDE_RSS_FEED_POST} from "../../data/feed";
import {SUGGEST_RSS_FEED_POST} from "../../data/rssFeed";
import {useFindOutWhoModal} from "./find-out-who-modal";
import {PostPreviewModal} from "./post-preview-modal";

import styles from "./feed.module.scss";

export interface FeedPostProps extends EmptyComponent {
	post: FeedPostType;
	title: ReactElement;
	header?: ReactNode;
	comment?: ReactNode;
	suggested?: boolean;
	showInitial?: boolean;
	rss?: boolean;
}

export const FeedPost: FC<FeedPostProps> = ({comment, header, post, rss, showInitial, suggested, title}) => {
	const [message, setMessage] = useState("");
	const me = useMyUser();
	const [suggestRssFeedPost, {loading}] = useMutationToast(SUGGEST_RSS_FEED_POST);
	const [hideSuggestedShare, {loading: loadingHide}] = useMutationToast(HIDE_FEED_POST);
	const [hideRssFeedPost, {loading: loadingHideRssFeedPost}] = useMutationToast(HIDE_RSS_FEED_POST);
	const navigate = useNavigate();

	const hideModal = useConfirmModal(
		() => ({
			title: rss ? "Remove Post?" : "Remove Share?",
			body: rss
				? "If you remove a Post, it will no longer appear in Your Posts."
				: "If you remove a Share, it will no longer appear in Your Shares. Note that this will not remove any posts that may have already been shared to your networks.",
			confirming: loadingHide || loadingHideRssFeedPost,
			onConfirm: close => {
				(rss ? hideRssFeedPost : hideSuggestedShare)({variables: {id: post.id}}).finally(close);
			},
		}),
		[loadingHide, hideSuggestedShare, hideRssFeedPost, post.id, rss, loadingHideRssFeedPost]
	);

	const isAdmin = me.role === "admin";
	const C = header ? CollapsibleCard : Card;
	const props: CollapsibleCardProps = {className: styles.card, showInitial};
	if (header) props.header = header;
	const postLink = (
		<Span href={post.url} trim={1}>
			{post.item.title ?? post.url}
		</Span>
	);
	const {open} = useModal(
		({close}) => ({
			header: <h3>Suggest Post to Admins</h3>,
			body: (
				<Text
					label="Add a message to your admins"
					value={message}
					onChange={setMessage}
					type="textarea"
					autoFocus
				/>
			),
			footer: (
				<Button
					value="Suggest"
					onClick={() => suggestRssFeedPost({variables: {comment: message, id: post.id}}).then(close)}
					loading={loading}
				/>
			),
			onClose: () => setMessage(""),
		}),
		[loading, message, post.id, suggestRssFeedPost]
	);

	const {open: openAddToCollection} = useAddToCollectionModal({post, rss});
	const handleSharePersonalPost = useCallback(
		() =>
			post.userId === me.id
				? navigate(`/collections/posts/${post.id}`, {
						state: {
							type: "personal",
							action: post.shareStatus !== "scheduled" ? "share" : "edit",
							id: post.id,
						},
				  })
				: navigate("/collections/posts/new", {
						state: {id: post.id, type: "personal", rss},
				  }),
		[me.id, navigate, post.id, post?.userId, post.shareStatus, rss]
	);
	const createOptions = useMemo(
		() => [
			{
				label: "Company",
				onClick: () => {
					navigate("/collections/posts/new", {
						state: {
							id: post.id,
							type: "company",
							rss,
							companyId: post?.company?.id,
						},
					});
				},
			},
			{label: "Personal", onClick: handleSharePersonalPost},
		],
		[navigate, post?.company?.id, post.id, handleSharePersonalPost, rss]
	);

	const findOutWhoModal = useFindOutWhoModal({title: "Find Out Who", visitors: post?.visitors || []});
	// prettier-ignore
	const action =
		post.shareStatus === "scheduled" ? "Scheduled" :
		post.shareStatus === "shared" ? "Share Again" :
		"Share Post";

	const buttons: ReactElement[] = [];
	if (isAdmin)
		buttons.push(
			<Button
				key="add-to-collection"
				onClick={openAddToCollection}
				value="Add to Collection"
				icon="add"
				invert
				border={false}
				color="black"
			/>
		);
	buttons.push(
		isAdmin ? (
			<DropdownButton
				key="share-admin"
				value={action}
				icon="post"
				invert
				border={false}
				color="black"
				className={styles.dropdownButton}
				options={createOptions}
			/>
		) : (
			<Button
				key="share-user"
				onClick={handleSharePersonalPost}
				disabled={post.shareStatus === "scheduled"}
				value={action}
				border={false}
				color="black"
				className={styles.dropdownButton}
				icon="post"
				invert
			/>
		)
	);
	if (!rss && isAdmin && post?.sharedAt)
		buttons.push(
			<Button
				key="analytics"
				onClick={() => navigate("/analytics/collections")}
				value="See Analytics"
				icon="analytics"
				invert
				border={false}
				color="black"
			/>
		);

	if (isAdmin && !rss) {
		buttons.push(
			<Button
				key="hide"
				onClick={hideModal.open}
				icon="delete"
				invert
				color="black"
				border={false}
				value="Remove Share"
			/>
		);
	}

	if (rss) {
		buttons.push(
			<Button
				key="hide"
				onClick={hideModal.open}
				icon="delete"
				invert
				color="black"
				border={false}
				value="Remove Post"
			/>
		);
	}

	if (post?.clickCount) {
		const text = `${post.clickCount} ${post.clickCount === 1 ? "Click" : "Clicks"}`;
		buttons.push(
			post.visitors?.length ? (
				<Button
					key="clicks"
					onClick={findOutWhoModal.open}
					value={text}
					invert
					border={false}
					color="black"
				/>
			) : (
				<Span className={styles.clicks}>{text}</Span>
			)
		);
	}

	const {open: openPostPreview, modal: postPreviewModal} = useNewModal({size: "medium"});

	return (
		<>
			<C {...props}>
				<div className={styles.header}>
					<div className={styles.title}>{title}</div>
					<div className={styles.createdAt}>
						{post?.sharedAt
							? `${rss ? "Created" : "Shared"} ${post.sharedAt.fromNow()}`
							: `Recommended ${post.createdAt.fromNow()}`}
					</div>
				</div>
				{comment}
				<Separator horizontal className={styles.divider} />
				{post.item.video ? (
					<video className={styles.image} controls muted>
						<source src={post.item.video} />
						<track kind="captions" srcLang="en" label="english_captions" />
					</video>
				) : (
					post.item.image && (
						<div className={styles.image} onClick={openPostPreview}>
							<img src={post.item.image} alt={post.item.description} />
						</div>
					)
				)}
				{post.item.description && <P2>{post.item.description}</P2>}
				{suggested != null ? (
					<InputRow position="between">
						{postLink}
						<SmallButton
							value={`${suggested ? "Suggested" : "Suggest"} to Admins`}
							disabled={suggested}
							onClick={open}
							icon="like"
							color="black"
							border={false}
							invert
						/>
					</InputRow>
				) : (
					postLink
				)}
				<Separator horizontal className={styles.divider} />
				{buttons.length === 1 ? (
					<InputRow position="center">{buttons}</InputRow>
				) : (
					<MultiColumn columns={(buttons.length < 5 ? buttons.length : 4) as 2 | 3 | 4} center>
						{buttons}
					</MultiColumn>
				)}
			</C>
			<PostPreviewModal modal={postPreviewModal} post={post} rss={rss} />
		</>
	);
};

export {Company} from "./company";
export {Explore} from "./explore";
export {MyHub} from "./my-hub";
