import {
	KeyboardEvent,
	ReactElement,
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import classnames from "classnames";
import {Breathing} from "react-shimmer";
import {useQuery} from "@apollo/client";

import {count} from "../../utils/text";
import {EmptyComponent} from "../../types";
import {Card} from "../card";
import {useMutationToast} from "../../toast";
import {Button, Checkbox, InputRow, MultiColumn, Select, Text, required} from "../input";
import {Span, Span2} from "../text";
import {Icon} from "../images";
import {useConfirmModal, useModal} from "../../modals";
import {ImageLoader} from "./image-loader";
import {ImageEditor} from ".";
import {FileUpload} from "../input/file-upload";
import {
	DELETE_IMAGE,
	DELETE_VIDEO,
	GET_IMAGE_LIST,
	GET_VIDEO_LIST,
	IMAGE_CREATORS,
	Media,
	UPDATE_MEDIA_IMAGE,
	UPLOAD_MEDIA_IMAGE,
	VIDEO_CREATORS,
} from "../../data/media";
import {useMyUser} from "../../data";
import {Loading} from "../loading";
import {useDebounce} from "../../debounce";
import {useDebounceCallback} from "../../hooks/use-debounce-callback";
import {useVideoUpload} from "../../hooks/use-video-upload";

import styles from "./media-selector.module.scss";

interface MediaSelectorProps extends EmptyComponent {
	type: "image" | "video";
	selectedMedia: Media[];
	onChangeSelectedMedia: (media: Media[]) => void;
	onConfirm: () => void;
}

export const MediaSelector = ({
	className,
	selectedMedia,
	onChangeSelectedMedia,
	type,
	onConfirm,
}: MediaSelectorProps): ReactElement => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [imageSize, setImageSize] = useState({width: 0, height: 0});
	const [isDetailView, setIsDetailView] = useState(false);
	const [page, setPage] = useState(1);
	const containerRef = useRef<HTMLDivElement>(null);
	const [file, setFile] = useState<File>();
	const [createdBy, setCreatedBy] = useState<number>();
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 500);
	const me = useMyUser();
	const [updateMediaImage] = useMutationToast(UPDATE_MEDIA_IMAGE);
	const [deleteImage, {loading: loadingDeleteImage}] = useMutationToast(DELETE_IMAGE);
	const [deleteVideo, {loading: loadingDeleteVideo}] = useMutationToast(DELETE_VIDEO);
	const loadingDelete = loadingDeleteImage || loadingDeleteVideo;
	const [uploadImage, {loading: uploading}] = useMutationToast(UPLOAD_MEDIA_IMAGE);
	const [detailedTitle, setDetailedTitle] = useState("");
	const {uploadFile, loading: uploadingFile} = useVideoUpload();

	const onShowDetails = useCallback(() => {
		setIsDetailView(true);
		setDetailedTitle(selectedMedia?.[0]?.title ?? "");
	}, [selectedMedia]);

	useEffect(() => {
		setPage(1);
	}, [debouncedSearch]);
	useEffect(() => {
		setCreatedBy(undefined);
		setSearch("");
		setPage(1);
	}, [type]);

	const {data: dataUsers} = useQuery(type === "image" ? IMAGE_CREATORS : VIDEO_CREATORS);
	const users = useMemo(() => {
		const data = type === "image" ? dataUsers?.imageCreators : dataUsers?.videoCreators;
		if (!data) return [];
		return [...data.map(el => ({label: [el.lastName, el.firstName].join(", "), value: el.id}))].sort((a, b) =>
			a.label.localeCompare(b.label)
		);
	}, [type, dataUsers?.imageCreators, dataUsers?.videoCreators]);
	const [loadingMore, setLoadingMore] = useState(false);

	const {data: dataImages, loading: loadingImages, fetchMore: fetchMoreImages} = useQuery(GET_IMAGE_LIST, {
		variables: {
			page: page,
			search: debouncedSearch,
			createdBy: createdBy,
		},
		skip: type === "video",
	});
	const {data: dataVideos, loading: loadingVideos, fetchMore: fetchMoreVideos} = useQuery(GET_VIDEO_LIST, {
		variables: {
			page: page,
			search: debouncedSearch,
			createdBy: createdBy,
		},
		skip: type === "image",
	});

	const videoIdsInProcessing = useMemo(
		() =>
			type === "video" && dataVideos?.videoList?.items
				? dataVideos.videoList.items
						.filter(video => ["PENDING", "STARTED"].includes(video.status))
						.map(video => video.id)
				: [],
		[type, dataVideos]
	);

	const {startPolling, stopPolling} = useQuery(GET_VIDEO_LIST, {
		variables: {
			ids: videoIdsInProcessing,
		},
		skip: videoIdsInProcessing.length === 0,
	});

	useEffect(() => {
		if (videoIdsInProcessing.length === 0) return stopPolling();
		startPolling(5000);
	}, [startPolling, stopPolling, videoIdsInProcessing]);

	const loading = loadingImages || loadingVideos || loadingMore;
	const hasMoreItems =
		type === "image"
			? dataImages?.imageList?.page < dataImages?.imageList?.totalPages
			: dataVideos?.videoList?.page < dataVideos?.videoList?.totalPages;
	const media = useMemo(() => {
		const data = type === "image" ? dataImages?.imageList?.items : dataVideos?.videoList?.items;
		if (!data) return [];
		return data.map(m => ({
			id: m.id,
			url: m.url ?? m.originalUrl,
			alt: m.title ?? m.filename,
			title: m.title ?? m.filename,
			isDefault: "isDefault" in m ? m.isDefault : false,
			type,
			...(type === "video" && {thumbnailUrl: m.thumbnailUrl, status: m.status}),
		}));
	}, [type, dataImages, dataVideos]);

	const handleDelete = useCallback(
		close => {
			const onComplete = () => {
				close();
			};

			if (type === "image") {
				deleteImage({variables: {imageId: selectedMedia[0].id}}).then(() => {
					onComplete();
				});
			} else {
				deleteVideo({variables: {id: selectedMedia[0].id}}).then(() => {
					onComplete();
				});
			}
		},
		[selectedMedia, type, deleteImage, deleteVideo]
	);
	const confirmDeleteModal = useConfirmModal(
		() => ({
			title: `Delete selected ${type}s?`,
			body: `Are you sure you want to delete these ${type}s? Once you remove ${
				type === "image" ? "an image" : "a video"
			} it will no longer appear in your media library.`,
			confirmColor: "pink",
			onConfirm: handleDelete,
			confirming: loadingDelete,
		}),
		[handleDelete, type, loadingDelete]
	);

	const handleSetDefaultImage = useCallback(
		close => {
			const selected = selectedMedia[0];
			updateMediaImage({variables: {imageId: selected.id, isDefault: true}});
			close();
		},
		[selectedMedia, updateMediaImage]
	);
	const confirmMakeDefaultImageModal = useConfirmModal(
		() => ({
			title: "Set image as default?",
			body:
				"Do you want to set this image as default? Once you set an image as default, it will be used by default for all the upcoming new posts.",
			onConfirm: handleSetDefaultImage,
		}),
		[handleSetDefaultImage]
	);

	const handleScroll = useCallback(() => {
		const container = containerRef.current;

		if (!container) return;
		if (
			container.scrollTop + container.clientHeight >= container.scrollHeight - 15 &&
			!loading &&
			hasMoreItems
		) {
			setLoadingMore(true);
			if (type === "image") {
				fetchMoreImages({
					variables: {
						page: page + 1,
					},
				}).then(() => {
					setLoadingMore(false);
				});
			} else {
				fetchMoreVideos({
					variables: {
						page: page + 1,
					},
				}).then(() => {
					setLoadingMore(false);
				});
			}
			setPage(page + 1);
		}
	}, [loading, page, fetchMoreImages, hasMoreItems, fetchMoreVideos, type]);

	useEffect(() => {
		const container = containerRef.current;

		if (!container) return;

		container.addEventListener("scroll", handleScroll);
		return () => {
			container.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	const scrollToTop = useCallback(() => {
		const list = containerRef.current;

		if (!list || selectedMedia.length === 0) return;

		const index = media.findIndex(m => m.id === selectedMedia[0].id) + 1 + (uploading ? 1 : 0);
		const item = containerRef.current.children[index];
		item.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	}, [media, selectedMedia, uploading]);

	useEffect(() => {
		if (isDetailView) {
			scrollToTop();
		}
	}, [isDetailView, scrollToTop]);

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (!selectedMedia.length || selectedMedia.length > 1) {
			setIsDetailView(false);
		}
	}, [selectedMedia]);

	const handleSelectMediaKeyDown = (e: KeyboardEvent<HTMLDivElement>, m: Media, selected: boolean) => {
		if (m.type === "video" && !(m.url && m.thumbnailUrl)) return;
		switch (e.key) {
			case "Enter":
			case " ":
				onChangeSelectedMedia(
					selected ? selectedMedia.filter(existing => existing.id !== m.id) : [...selectedMedia, m]
				);

				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};
	const handleSelectMedia = (media: Media) => {
		setDetailedTitle(media.title);
		return onChangeSelectedMedia([media]);
	};

	const handleImageLoad = event => {
		const {naturalWidth, naturalHeight} = event.target;

		setImageSize({width: naturalWidth, height: naturalHeight});
	};

	const handleFileChange = (currentFile?: File) => {
		if (!currentFile) {
			return;
		}

		setFile(currentFile);

		if (!currentFile) {
			return;
		}

		if (type === "image") {
			const formData = new FormData();
			formData.append(type, currentFile);
			formData.append("name", type);
			uploadImage({variables: {file: currentFile, title: currentFile.name}});
		} else {
			uploadFile(currentFile);
		}
	};

	const handleImageUpdate = useCallback(
		async (file: File) => {
			const formData = new FormData();
			formData.append(type, file);
			formData.append("name", type);
			formData.append("title", selectedMedia[0].title);

			const res = await updateMediaImage({variables: {imageId: selectedMedia[0].id, file: file}});
			onChangeSelectedMedia(
				selectedMedia.map(media => {
					if (media.id === selectedMedia[0].id) {
						media = {...media, ...res?.data?.updateMediaImage};
					}
					return media;
				})
			);
		},
		[selectedMedia, type, updateMediaImage, onChangeSelectedMedia]
	);

	const editImageModal = useModal(
		({close}) => ({
			size: "large",
			body: (
				<Suspense fallback={<Loading />}>
					<ImageEditor
						imageUrl={selectedMedia[0]?.url}
						onSave={file => {
							close();
							handleImageUpdate(file);
						}}
						onClose={close}
					/>
				</Suspense>
			),
		}),
		[selectedMedia, handleImageUpdate]
	);

	const updateImageTitleCb = useCallback(
		({title}) => {
			const id = selectedMedia[0].id;
			updateMediaImage({variables: {imageId: id, title}});
		},
		[selectedMedia, updateMediaImage]
	);

	const debouncedUpdateImageTitleCb = useDebounceCallback(updateImageTitleCb, 300);

	const handleChangeTitle = (title: string) => {
		setDetailedTitle(title);
		debouncedUpdateImageTitleCb({title});
	};

	const determineGridRowNumber = (): number => {
		if (windowWidth >= 1024) {
			return 4;
		}
		if (windowWidth >= 768) {
			return 3;
		}

		return 2;
	};

	const Shimmers = (fill: number) =>
		Array(fill)
			.fill(null)
			.map((m, i) => <Breathing className={styles.mediaItem} key={i} />);

	return (
		<div className={classnames(styles.container, className)}>
			<>
				<div className={styles.headerButtons}>
					{selectedMedia.length > 0 ? (
						<div className={styles.selectButtonContainer}>
							<h5 className={styles.selectedItemsText}>{count(selectedMedia, "item")} selected</h5>
							<div className={styles.selectItemsContainer}>
								<InputRow position="left">
									<Button
										value={`Deselect${selectedMedia.length > 1 ? " All" : ""}`}
										invert
										onClick={() => {
											setIsDetailView(false);
											onChangeSelectedMedia([]);
										}}
									/>
									<Button value="Delete" icon="delete" invert onClick={confirmDeleteModal.open} />
								</InputRow>
							</div>

							<div className={styles.selectItemsContainer}>
								<InputRow position="right">
									<Button
										value="Detail View"
										disabled={selectedMedia.length !== 1}
										invert
										onClick={onShowDetails}
									/>
									{type === "image" && (
										<Button
											value="Edit content"
											disabled={selectedMedia.length !== 1}
											invert
											onClick={editImageModal.open}
										/>
									)}
								</InputRow>
							</div>
						</div>
					) : (
						<InputRow position="left" className={styles.filterButtons}>
							<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
							<Select
								placeholder="Created by"
								value={createdBy}
								onChange={setCreatedBy}
								options={[
									{
										label: "All Users",
										value: undefined,
									},
									...users,
								]}
							/>
						</InputRow>
					)}
				</div>
				<div className={styles.mediaContainer} ref={containerRef}>
					{me?.role === "admin" && (
						<div className={classnames(styles.mediaItem, styles.uploadItem)}>
							<FileUpload
								iconSize={80}
								onChange={handleFileChange}
								type={type}
								value={file}
								className={styles.upload}
								disableTooltip
							/>
							{uploadingFile && (
								<div className={classnames(styles.uploadingFile)}>
									<Breathing />
									<Loading position="absolute" />
								</div>
							)}
						</div>
					)}
					{uploading && Shimmers(1)}
					{media.map((m, i) => {
						const selected = selectedMedia.find(c => m.id === c.id);
						const disabled = type === "video" ? !(m.thumbnailUrl && m.url) : false;
						return (
							<>
								<div
									className={classnames(
										styles.mediaItem,
										styles.previewCard,
										selected && styles.selected,
										disabled && styles.disabled
									)}
									key={`${m.id}-${m.type}-${m.url}`}
									tabIndex={0}
									onKeyDown={e => handleSelectMediaKeyDown(e, m, !!selected)}
									onClick={() => handleSelectMedia(m)}
								>
									<Checkbox className={styles.mediaItemCheckbox} value={!!selected} onChange={() => null} />
									{type === "image" && <ImageLoader media={m} className={styles.image} />}
									{type === "video" &&
										(!disabled ? (
											<video className={styles.video} src={m.url} controls muted>
												<source src={m.url} />
												<track kind="captions" srcLang="en" label="english_captions" />
											</video>
										) : (
											<>
												<video className={styles.video} src={m.url} muted>
													<source src={m.url} />
													<track kind="captions" srcLang="en" label="english_captions" />
												</video>
												<div className={styles.processing}>
													{["PENDING", "STARTED"].includes(m.status) ? (
														<>
															<Loading size="small" />
															<Span2 bold>Processing video and creating thumbnail</Span2>
														</>
													) : (
														<Span2 bold>
															Sorry, we’ve run into an error processing your video. Please try uploading it
															again.
														</Span2>
													)}
												</div>
											</>
										))}
									{type === "image" && (
										<Span trim={1} color="grey" className={styles.previewTitle}>
											{m.title}
										</Span>
									)}
								</div>
								{selected && isDetailView && (
									<div
										tabIndex={0}
										style={{gridRow: `${Math.ceil((i + 2) / determineGridRowNumber()) + 1}`}}
										className={classnames(styles.mediaItem, styles.detailedCard)}
									>
										<Card className={styles.card}>
											<div className={styles.header}>
												<h3 className={styles.headerTitle}>Detail View</h3>
												<Icon icon="close" onClick={() => setIsDetailView(false)} />
											</div>

											<div className={styles.body}>
												<div className={classnames(styles.placeholder, styles.imageContainer)}>
													{type === "image" && (
														<img alt={m.alt} className={styles.image} src={m.url} onLoad={handleImageLoad} />
													)}
													{type === "video" && (
														<video className={styles.video} src={m.url} controls muted>
															<source src={m.url} />
															<track kind="captions" srcLang="en" label="english_captions" />
														</video>
													)}
												</div>
												<div className={classnames(styles.placeholder, styles.inputContainer)}>
													{type === "image" ? (
														<>
															<MultiColumn className={[styles.inputs, styles.info]}>
																<Text
																	text={`${imageSize.width} x ${imageSize.height}px`}
																	placeholder="Image name"
																	// value={m.title}
																	value={detailedTitle}
																	onChange={handleChangeTitle}
																	validate={required}
																	maxLength={100}
																/>
																<Checkbox
																	disabled={m.isDefault}
																	value={m.isDefault}
																	onChange={confirmMakeDefaultImageModal.open}
																	label="Set as Default"
																/>
															</MultiColumn>
															<InputRow position="between">
																<Button
																	value="Edit Content"
																	icon="magic"
																	invert
																	onClick={editImageModal.open}
																/>
																<Button value="Select Image" icon="check" onClick={onConfirm} />
															</InputRow>
														</>
													) : (
														<InputRow position="right">
															<Button value="Select Video" icon="check" onClick={onConfirm} />
														</InputRow>
													)}
												</div>
											</div>
										</Card>
									</div>
								)}
							</>
						);
					})}
					{loading && Shimmers(8)}
				</div>
			</>
		</div>
	);
};
