import {useCallback, useEffect, useMemo, useState} from "react";

import {BaseModalArgs} from "..";
import {ModalData, Modal} from "../new";
import {Post, UPDATE_POST} from "../../data";
import {Tab} from "../../components/tabs/tab";
import {SetDirtyFields, DirtyFields, SfuffleCaptionActions, ShuffleCaptionTab} from "./shuffle-caption-tab";
import {SfuffleImageActions, ShuffleImageTab} from "./shuffle-image-tab";
import {useMutationToast} from "../../toast";

import styles from "./shuffle-content.module.scss";

export interface ShuffleContentModalArgs<T> extends BaseModalArgs<T> {
	collectionId: number;
	post: Post;
	disabled: boolean;
	modal: ModalData;
	dirtyFields: DirtyFields;
	setDirtyFields: SetDirtyFields;
	tabs?: {
		caption?: boolean;
		image?: boolean;
	};
}

const loadImages = (post: Post): string[] => {
	const images: string[] = [...(post?.shuffledImages?.map(({image}) => image) || [])];

	const postImage = post?.opengraphs?.general?.image;

	if (postImage && !images.includes(postImage)) {
		images.push(postImage);
	}

	return images;
};

export const ShuffleContentModal = <T,>({
	collectionId,
	post,
	disabled,
	modal,
	dirtyFields,
	setDirtyFields,
	tabs = {caption: true, image: true},
}: ShuffleContentModalArgs<T>) => {
	const [selectedTab, setSelectedTab] = useState<"caption" | "image">("caption");
	const [updatePost, {loading: updating}] = useMutationToast(UPDATE_POST);
	const [images, setImages] = useState<string[]>(() => loadImages(post));
	const reloadImages = useCallback(() => setImages(() => loadImages(post)), [post]);

	useEffect(() => {
		if (modal.open) {
			reloadImages();
		}
	}, [modal.open, reloadImages]);

	const handleUpdateShuffledImages = useCallback(() => {
		updatePost({
			variables: {
				id: collectionId,
				postId: post.id,
				changes: {
					shuffledImages: images?.map(i => ({image: i})) ?? [],
				},
				deletions: {},
			},
			onCompleted: () => {
				modal.close();
			},
		});
	}, [updatePost, collectionId, post.id, images, modal]);

	const footer = useMemo(() => {
		if (selectedTab === "caption") {
			return <SfuffleCaptionActions post={post} modal={modal} />;
		}

		if (selectedTab === "image") {
			return <SfuffleImageActions onConfirm={handleUpdateShuffledImages} updating={updating} modal={modal} />;
		}
	}, [selectedTab, updating, handleUpdateShuffledImages, post, modal]);

	const description = useMemo(
		() => (
			<div className={styles.tabs}>
				{tabs.caption && (
					<Tab
						onClick={() => setSelectedTab("caption")}
						active={selectedTab === "caption"}
						title="Caption Shuffle"
					/>
				)}
				{tabs.image && (
					<Tab
						onClick={() => setSelectedTab("image")}
						active={selectedTab === "image"}
						title="Image Shuffle"
					/>
				)}
			</div>
		),
		[selectedTab, tabs]
	);

	return (
		<Modal modal={modal} title="Post Content" description={description} footer={footer} size="large">
			{tabs?.image && selectedTab === "image" ? (
				<ShuffleImageTab images={images} setImages={setImages} disabled={disabled} />
			) : (
				<ShuffleCaptionTab
					collectionId={collectionId}
					post={post}
					disabled={disabled}
					dirtyFields={dirtyFields}
					setDirtyFields={setDirtyFields}
				/>
			)}
		</Modal>
	);
};
