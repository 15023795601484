import React, {FC, useCallback, useMemo} from "react";
import {Formik, useFormikContext} from "formik";
import {Dayjs} from "dayjs";

import {
	ADD_POST,
	PostServices,
	Service,
	services,
	UPDATE_POST,
	MOVE_POST,
	DELETE_SHUFFLED_COMMENT,
	UPDATE_SHUFFLED_COMMENT,
	ADD_CATEGORY_TO_COLLECTION,
	ShuffledComment,
	ShuffledImage,
	OpenGraph,
} from "../../../../data";
import {Post as PostType} from "../../../../data/collection";
import {useMutationToast} from "../../../../toast";
import {CollectionFormValues} from "./collection-form";
import {PostEmv} from "./post-emv";
import {CREATE_SHUFFLED_COMMENTS, DELETE_SHUFFLED_COMMENTS} from "../../../../data/social-shuffle";
import {DragHandleProps} from "./drag-drop";
import {postValidationSchema} from "./validation-schema";
import {PostEditorSelector} from "./post-editor-selector";

import styles from "./styles.module.scss";

export type OnChange = (field: string, value: string | object | undefined | number | null) => void;

export type PostCollectionFormValues = PostType & {
	activeNetwork: PostServices;
	perNetwork: boolean;
	deletedShuffledComments: number[];
	shuffledComments: ShuffledComment[];
	shuffledImages: ShuffledImage[];
	collectionId: number;
};

export interface PostProps {
	post: PostType;
	collectionId?: number;
	onRemove?: () => void;
	dragHandleProps?: DragHandleProps;
	onValidityChange?: (valid: boolean) => void;
}

export const Post: FC<PostProps> = ({post, collectionId, onRemove, dragHandleProps, onValidityChange}) => {
	const {id, opengraphs, url, keywords, expiresAt, shuffledComments, shuffledImages, categoryId} = post;
	const {
		values: collectionValues,
		setFieldValue: setCollectionFieldValue,
		setSubmitting: setCollectionSubmitting,
	} = useFormikContext<CollectionFormValues>();
	const {categories} = collectionValues;
	const [addPost, {loading: loadingAddPost}] = useMutationToast(ADD_POST);
	const [updatePost] = useMutationToast(UPDATE_POST);
	const [createShuffledComments, {loading: loadingAddShuffle}] = useMutationToast(CREATE_SHUFFLED_COMMENTS);
	const [updateShuffledComment] = useMutationToast(UPDATE_SHUFFLED_COMMENT);
	const [deleteShuffleComment] = useMutationToast(DELETE_SHUFFLED_COMMENT);
	const [deleteShuffledComments] = useMutationToast(DELETE_SHUFFLED_COMMENTS);
	const [movePost] = useMutationToast(MOVE_POST);
	const [addCategory] = useMutationToast(ADD_CATEGORY_TO_COLLECTION);
	const hasShuffledContent = shuffledComments?.length || shuffledImages?.length;
	const perNetwork = useMemo(() => Object.values(opengraphs ?? {}).filter(item => !!item).length > 1, [
		opengraphs,
	]);
	const initialValues = useMemo(
		() => ({
			id,
			opengraphs,
			url,
			keywords,
			expiresAt,
			activeNetwork: "general",
			perNetwork,
			shuffledComments: hasShuffledContent ? shuffledComments : undefined,
			shuffledImages: hasShuffledContent ? shuffledImages : undefined,
			categoryId,
			collectionId,
		}),
		[
			id,
			opengraphs,
			url,
			keywords,
			expiresAt,
			perNetwork,
			shuffledComments,
			shuffledImages,
			categoryId,
			collectionId,
			hasShuffledContent,
		]
	) as PostCollectionFormValues;

	const maybeCreateShuffledComments = useCallback(
		(values: PostCollectionFormValues, setFieldValue) => {
			const {shuffledComments, perNetwork} = values;

			if (perNetwork || !shuffledComments?.length) {
				return Promise.resolve();
			}
			const newShuffleComments = [...shuffledComments].filter(sc => !sc.id && !sc.isSending);
			createShuffledComments({
				variables: {
					shuffleComments: newShuffleComments.map(sc => ({
						queuedUrlId: id,
						comment: sc.comment,
					})),
				},
			}).then(({data}) => {
				const newShuffleComments = data.createShuffledComments;
				newShuffleComments.forEach((sc, index) => {
					const tempId = shuffledComments.findIndex(c => c.tempId === newShuffleComments[index].tempId);
					setFieldValue(`shuffledComments[${tempId}].id`, sc.id);
				});
			});
		},
		[id, createShuffledComments]
	);

	const maybeUpdateShuffledComments = useCallback(
		(values: PostCollectionFormValues) => {
			const {shuffledComments: updatedShuffledComments, perNetwork} = values;

			if (perNetwork || !updatedShuffledComments?.length) {
				return Promise.resolve();
			}

			const promises = updatedShuffledComments
				?.filter(
					sc =>
						shuffledComments?.find(c => c.id === sc.id) &&
						sc.comment &&
						shuffledComments?.find(c => c.id === sc.id)?.comment !== sc.comment
				)
				?.map(sc => updateShuffledComment({variables: {id: sc.id, comment: sc.comment}}));

			return Promise.all(promises || []);
		},
		[updateShuffledComment, shuffledComments]
	);

	const maybeDeleteShuffledComments = useCallback(
		(values: PostCollectionFormValues) => {
			const {perNetwork, shuffledComments: updatedShuffledComments} = values;

			if (shuffledComments?.length && !updatedShuffledComments?.length) {
				return deleteShuffledComments({variables: {queuedUrlId: id}});
			}

			const promises = shuffledComments
				.filter(sc => perNetwork || !updatedShuffledComments?.find(c => c.id === sc.id))
				.map(sc => deleteShuffleComment({variables: {id: sc.id}}));

			return Promise.all(promises);
		},
		[deleteShuffleComment, deleteShuffledComments, shuffledComments, id]
	);

	const maybeUpdateShuffledImages = useCallback(
		(values: PostCollectionFormValues) => {
			const {shuffledImages: updatedShuffledImages, perNetwork} = values;
			const newImages = (updatedShuffledImages ?? []).filter(si => si.image).map(si => si.image);
			const oldImages = (shuffledImages ?? []).map(si => si.image);

			if (
				perNetwork ||
				(newImages.every(image => oldImages.includes(image)) &&
					oldImages.every(image => newImages.includes(image)))
			) {
				return Promise.resolve();
			}

			return updatePost({
				variables: {
					id: collectionId,
					postId: id,
					changes: {
						shuffledImages: newImages?.map(i => ({image: i})) ?? [],
					},
					deletions: {},
				},
			});
		},
		[id, collectionId, updatePost, shuffledImages]
	);

	const maybeMovePost = useCallback(
		async (values: PostCollectionFormValues) => {
			if (!values.categoryId || values.categoryId === categoryId) return Promise.resolve();

			if (!categories?.includes(values.categoryId)) {
				await setCollectionFieldValue("categories", [...(categories || []), values.categoryId]);
				await addCategory({variables: {id: collectionId, categoryId: values.categoryId}});
			}

			return movePost({
				variables: {id: collectionId, postId: id, categoryId: values.categoryId},
			});
		},
		[collectionId, id, movePost, addCategory, setCollectionFieldValue, categories, categoryId]
	);

	const maybeUpdatePost = useCallback(
		(values: PostCollectionFormValues) => {
			const changes = {} as {
				keywords?: number[];
				url?: string | null;
				expiresAt?: Dayjs;
				opengraphs?: OpenGraph;
			};
			const deletions = {} as {expiresAt?: boolean; opengraphs?: Record<Service, boolean>};

			if (JSON.stringify(values.keywords) !== JSON.stringify(keywords)) {
				changes.keywords = values.keywords.map(k => k.id);
			}

			if (values.url !== url) {
				changes.url = values.url;
			}

			if (values.expiresAt !== expiresAt) {
				changes.expiresAt = values.expiresAt;
			}

			if (expiresAt && values.expiresAt === undefined) {
				deletions.expiresAt = true;
			}

			const updatedOpengraphs = Object.keys(values.opengraphs).reduce((acc, n) => {
				if (
					Object.entries(values.opengraphs[n] ?? {}).some(
						([property, v]) => opengraphs[n]?.[property] !== v ?? ""
					)
				) {
					acc[n] = {
						...values.opengraphs[n],
						...((values.opengraphs[n].image || values.opengraphs[n].video) &&
						["Text Share", "Image Share", "Video Share", ""].includes(opengraphs[n]?.title ?? "")
							? {title: `${values.opengraphs[n].video ? "Video" : "Image"} Share`}
							: {}),
					};
				}

				return acc;
			}, {});

			if (Object.keys(updatedOpengraphs).length) {
				changes.opengraphs = updatedOpengraphs;
			}

			const deletedOpengraphs = (values.perNetwork
				? (Object.keys(values.opengraphs) as Service[])
						.filter(n => services.includes(n) && opengraphs[n] && !values.opengraphs[n])
						.reduce((acc, n) => ({...acc, [n]: true}), {})
				: {}) as Record<Service, boolean>;

			if (Object.keys(deletedOpengraphs).length) {
				deletions.opengraphs = deletedOpengraphs;
			}

			if (!Object.keys(changes).length && !Object.keys(deletions).length) {
				return Promise.resolve();
			}

			return updatePost({
				variables: {
					id: collectionId,
					postId: id,
					changes,
					deletions,
				},
			});
		},
		[updatePost, id, collectionId, expiresAt, keywords, opengraphs, url]
	);

	const update = useCallback(
		(values: PostCollectionFormValues, setFieldValue) =>
			Promise.all([
				maybeCreateShuffledComments(values, setFieldValue),
				maybeUpdateShuffledComments(values),
				maybeDeleteShuffledComments(values),
				maybeUpdateShuffledImages(values),
				maybeMovePost(values),
				maybeUpdatePost(values),
			]),
		[
			maybeUpdatePost,
			maybeCreateShuffledComments,
			maybeDeleteShuffledComments,
			maybeUpdateShuffledComments,
			maybeMovePost,
			maybeUpdateShuffledImages,
		]
	);

	const create = useCallback(
		(values: PostCollectionFormValues) => {
			const {opengraphs, url} = values;
			const openGraphData = opengraphs.general as OpenGraph;

			return addPost({
				variables: {
					id: collectionId,
					url,
					openGraphData,
				},
			});
		},
		[addPost, collectionId]
	);

	const onSubmit = useCallback(
		async (values: PostCollectionFormValues, {setSubmitting, setFieldValue}) => {
			setSubmitting(true);
			setCollectionSubmitting(true);
			if (loadingAddPost) return;
			if (id) {
				await update(values, setFieldValue);
			} else {
				await create(values).then(() => onRemove?.());
			}

			setSubmitting(false);
			setCollectionSubmitting(false);
		},
		[id, update, create, onRemove, setCollectionSubmitting, loadingAddPost]
	);
	return (
		<div className={styles.post}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={postValidationSchema}
				validateOnChange
				validateOnMount
			>
				<div className={styles.postForm}>
					<PostEditorSelector
						onRemove={onRemove}
						loadingAddShuffle={loadingAddShuffle}
						dragHandleProps={dragHandleProps}
						onValidityChange={onValidityChange}
					/>
					<PostEmv />
				</div>
			</Formik>
		</div>
	);
};
