import {ReactElement, useCallback, useEffect, useState} from "react";
import classNames from "classnames";

import {Icon} from "../images";

import styles from "./slider.module.scss";

interface SliderProps {
	images: string[];
	showIndicator?: boolean;
}

export const Slider = ({images = [], showIndicator}: SliderProps): ReactElement => {
	const [activeImageIndex, setActiveImageIndex] = useState(0);
	const multiImage = images.length > 1;
	const onPrevClick = useCallback(
		() =>
			setActiveImageIndex(activeImageIndex =>
				activeImageIndex < 1 ? images.length - 1 : activeImageIndex - 1
			),
		[images]
	);
	const onNextClick = useCallback(
		() =>
			setActiveImageIndex(activeImageIndex =>
				activeImageIndex === images.length - 1 ? 0 : activeImageIndex + 1
			),
		[images]
	);

	useEffect(() => {
		const handleKeyDown = e => {
			switch (e.key) {
				case "ArrowLeft":
				case "ArrowUp":
					onPrevClick();
					break;
				case "ArrowRight":
				case "ArrowDown":
					onNextClick();
					break;
				default:
					return;
			}

			e.stopPropagation();
			e.preventDefault();
		};

		window.addEventListener("keydown", handleKeyDown);

		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [onPrevClick, onNextClick]);

	return (
		<div className={styles.slider}>
			{multiImage && (
				<div className={classNames(styles.sliderBtn, styles.left)} onClick={onPrevClick}>
					<Icon icon={"chevron-up"} />
				</div>
			)}
			{images.map((img, i) => (
				<div key={i} className={classNames(styles.slideImage, {[styles.active]: i === activeImageIndex})}>
					<img src={img || ""} alt={""} />
				</div>
			))}
			{multiImage && (
				<div className={classNames(styles.sliderBtn, styles.right)} onClick={onNextClick}>
					<Icon icon={"chevron-up"} />
				</div>
			)}
			{multiImage && showIndicator && (
				<div className={styles.slideIndicator}>
					{images.map((_, i) => (
						<div
							key={i}
							className={classNames(styles.dot, {[styles.active]: i === activeImageIndex})}
							onClick={() => setActiveImageIndex(i)}
						>
							<div />
						</div>
					))}
				</div>
			)}
		</div>
	);
};
