import {useQuery} from "@apollo/client";
import {FC, useCallback, useMemo, useState} from "react";
import classnames from "classnames";
import {Dayjs} from "dayjs";

import {Button, InputRow} from "../../components/input";
import {Span, Span4} from "../../components/text";
import {Card} from "../../components/card";
import {Loading} from "../../components/loading";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {OpengraphMedia} from "../../components/opengraph";
import {GET_POPULAR_POSTS, GET_POPULAR_POSTS_COUNT, PopularPost} from "../../data/analytics";

import styles from "./personal.module.scss";

const LIMIT = 5;

const renderPost = (post: PopularPost) => (
	<Card key={post.id} className={styles.topPost}>
		<div className={styles.postContent}>
			<OpengraphMedia className={styles.openGraphMedia} openGraph={post.openGraph} />
			<div className={styles.text}>
				<Span bold href={post.url}>
					{post.openGraph?.title}
				</Span>
				{(post.openGraph.comment || post.openGraph.description) && (
					<Span4 title={post.openGraph.comment || post.openGraph.description} trim={2}>
						{post.openGraph.comment || post.openGraph.description}
					</Span4>
				)}
			</div>
		</div>
		<div className={styles.postStats}>
			{[
				{value: post.clicks, label: "Clicks"},
				{value: post.shares, label: "Shares"},
				{value: `$${post.emv}`, label: "EMV"},
			].map(({value, label}) => (
				<div className={styles.stat} key={label}>
					<Span color="blue">{value}</Span>
					<Span color="grey">{label}</Span>
				</div>
			))}
		</div>
	</Card>
);

const ListPageControl: FC<{
	pageSize: number;
	pageNumber: number;
	total: number;
	hasMore: boolean;
	loading: boolean;
	onChange: (increment: number) => void;
}> = ({pageSize, pageNumber, total, hasMore, loading, onChange}) => (
	<InputRow className={styles.pageControl}>
		<Button onClick={() => onChange(-1)} icon="caret-left" disabled={pageNumber === 1} />
		<Span color="grey">
			Displaying {Math.min(total, pageSize * (pageNumber - 1) + 1)}-{Math.min(total, pageNumber * pageSize)}{" "}
			of {total}
		</Span>
		<Button onClick={() => onChange(1)} icon="caret-right" disabled={!hasMore || loading} />
	</InputRow>
);

export const UserPopularPosts: FC<{start: Dayjs; end: Dayjs}> = ({start, end}) => {
	const [pageNumber, setPageNumber] = useState(1);

	const variables = useMemo(() => ({start, end, limit: LIMIT}), [start, end]);

	const {data = [], fetchMore, cursor, loading} = usePaginatedQuery<PopularPost>(GET_POPULAR_POSTS, {
		loadingProps,
		variables,
		renderItem: renderPost,
	});

	const {data: countData} = useQuery(GET_POPULAR_POSTS_COUNT, {variables});
	const totalPosts = countData?.popularPostsCount ?? data.length;

	const hasMore = pageNumber * LIMIT < totalPosts || !!cursor;

	const onPageChange = useCallback(
		(increment: number) => {
			setPageNumber(prev => prev + increment);
			if ((pageNumber + increment) * LIMIT > data.length && cursor) {
				fetchMore({variables: {cursor}});
			}
		},
		[fetchMore, cursor, pageNumber, data.length]
	);

	const displayedPosts = useMemo(
		() => data.slice((pageNumber - 1) * LIMIT, Math.min(totalPosts, pageNumber * LIMIT)),
		[data, pageNumber, totalPosts]
	);

	return (
		<>
			<div className={classnames(styles.buttonsContainer, "space")}>
				<ListPageControl
					pageSize={LIMIT}
					pageNumber={pageNumber}
					total={totalPosts}
					hasMore={hasMore}
					loading={loading}
					onChange={onPageChange}
				/>
				<Span4 color="grey">{`${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`}</Span4>
			</div>

			{displayedPosts.length > 0
				? displayedPosts.map(renderPost)
				: !loading && (
						<InputRow position="center" className={styles.emptyMsg}>
							<Span>No posts found.</Span>
						</InputRow>
				  )}

			{loading && <Loading position="center" className={styles.loader} />}

			<div className={classnames(styles.buttonsContainer, "space")}>
				<ListPageControl
					pageSize={LIMIT}
					pageNumber={pageNumber}
					total={totalPosts}
					hasMore={hasMore}
					loading={loading}
					onChange={onPageChange}
				/>
			</div>
		</>
	);
};
